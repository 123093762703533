<template lang="pug">
.footer
  .overall-container
    .footer-content
      .left-footer
        .title
          h2 CONTACT US
        .contact-details
          #details1.details
            img(src="../assets/images/general/mail.svg")
            p lovemypetshaven@gmail.com
          #details2.details
            img(src="../assets/images/general/phone.svg")
            p +6019-6632828 (Aileen)
          #details3.details
            img(src="../assets/images/general/map-marker.svg")
            p A-2-G, Galleri Klang Sentral, Jalan Klang Sentral 15/KU5, 41050 Klang, Selangor, Malaysia.
      .right-footer
        .title
          h2 SOCIALS
        .social-container
          .social-logo
            a(href="https://www.instagram.com/mypetshaven/", target="__blank")
              img(src="../assets/images/general/instagram-square.svg")
          .social-logo
            a(href="https://www.facebook.com/mypetshaven/", target="__blank")
              img(src="../assets/images/general/facebook-square.svg")
    .copyright-container
      h3 © 2021 MyPetsHaven | #[a.link(href="/privacy-policy", target="__blank") Privacy Policy]
</template>
<script>
export default {
  data() {
    return {
      loggedIn: false,
      admin: false,
    };
  },
  methods: {},
};
</script>
<style lang="sass" scoped>
@import '../sass/variables'

.footer
  background-color: $primary-lighto
  .overall-container
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    .footer-content
      display: flex
      margin: 30px 0 40px 0
      max-width: 1280px
      justify-content: space-between
      +mobile
        flex-direction: column
        width: 80%
        margin: 30px auto
      .left-footer
        width: 70%
        display: flex
        flex-direction: column
        +mobile
          width: 100%
          margin-bottom: 20px
        .contact-details
          margin-top: 20px
          max-width: 80%
          +mobile
            max-width: 100%
            margin-top: 10px
          .details
            display: flex
            text-align: left
            margin: 5px 0
            &:nth-child(1)
              margin: 0
            img
              margin-right: 5px
            p
              margin: 0
              color: $primary-orange
          #details3
            img
              width: 28px
              height: 24px
      .right-footer
        width: 30%
        display: flex
        flex-direction: column
        .social-container
          display: flex
          margin-top: 20px
          +mobile
            margin-top: 10px
          .social-logo
            margin-right: 10px
            a
              height: 60px
              width: 60px
              img
                height: 100%
                width: 100%

.copyright-container
  margin: 5px 0 20px
  h3
    color: $primary-orange
    font-size: 16px
    font-weight: 300
  a
    color: $primary-orange
    font-size: 16px
    text-decoration: none

.title
  text-align: left
  color: $primary-orange
  h2
    font-size: 36px
    font-weight: bold
    margin-bottom: 0px
    +mobile
      font-size: 26px
      line-height: 30px
</style>