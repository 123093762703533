<template lang="pug">
#article
  v-overlay.fill-height(v-model="overlay", v-if="overlay !== false")
    v-row.overlayRow(no-gutters, justify="center", align="center")
      v-row.picRow(
        v-if="viewGallery.length !== 0",
        justify="start",
        no-gutters,
        align="center"
      )
        v-btn.leftBtn(
          :disabled="!(currentIndex !== 0)",
          @click="goBefore",
          small,
          fab,
          color="#FF9785"
        )
          v-icon mdi-arrow-left-drop-circle-outline

        v-img.picture(
          :src="viewGallery[currentIndex].url",
          style="cursor: pointer",
          loading="lazy"
        )

        v-btn.rightBtn(
          @click="goFront",
          small,
          fab,
          color="#FF9785",
          :disabled="!(currentIndex !== viewGallery.length - 1)"
        )
          v-icon mdi-arrow-right-drop-circle-outline

  .article-container
    //- .header-title
    //-   h1 - OUR FURBALL UPDATES -
    .content-wrapper
      .title-container
        h2 {{ mainArticle.title }}
        .date-tags-wrapper
          p {{ mainArticle.date }}
          .tags-container
            img(src="../assets/images/general/tags-o.png")
            p {{ mainArticle.tag }}
      .bottom-container
        .top-image
          img(:src="mainArticle.mainImage.url")
        .text-container
          p(v-html="mainArticle.content")
        .gallery-container
          img(
            v-for="(picture, index) in mainArticle.gallery",
            :src="picture.url",
            @click="clickPic(mainArticle.gallery, index)",
            loading="lazy"
          )
    hr.separator
    .other-articles
      .other-header
        h2 Other updates you might enjoy
      .articles-wrapper
        .article(
          v-for="article in otherArticles",
          @click="goToArticle(article.id)"
        )
          img.mainImg(:src="article.thumbnail.url")
          .tags-container
            img(src="../assets/images/general/tags-o.png")
            p {{ article.tag }}
          h3 {{ article.title }}
</template>
<script>
import { db } from "../main";
export default {
  name: "ArticlePage",

  data() {
    return {
      overlay: false,
      viewGallery: null,
      abs: true,
      currentIndex: null,
      mainArticle: null,
      otherArticles: [],
    };
  },
  async mounted() {
    document.getElementById("loading-screen").style.display = "flex";
    try {
      let blogRef = await db.collection("blogs").doc(this.$route.params.id);

      await blogRef.get().then((doc) => {
        this.mainArticle = doc.data();
      });

      let blog2Ref = await db.collection("blogs");

      var randomiseArray = [];
      await blog2Ref.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (this.$route.params.id !== doc.id) {
            var test = doc.data();
            test.id = doc.id;
            randomiseArray.push(test);
          }
        });
      });
      document.getElementById("loading-screen").style.display = "none";
    } catch (e) {
      console.error(e);
      document.getElementById("loading-screen").style.display = "none";
    }

    var number = 0;
    while (number < 3) {
      var randomInt = [Math.floor(Math.random() * randomiseArray.length)];
      if (!this.otherArticles.includes(randomiseArray[randomInt])) {
        this.otherArticles.push(randomiseArray[randomInt]);
        number++;
      }
    }
    // console.log(this.otherArticles);
  },
  watch: {
    overlay(val) {
      if (val) {
        document.addEventListener("mousedown", this.mousedownHandler);
      } else {
        document.removeEventListener("mousedown", this.mousedownHandler);
      }
    },
  },

  methods: {
    mousedownHandler(e) {
      if (
        e.target.classList.contains("v-overlay__scrim") ||
        e.target.classList.contains("overlayRow")
      ) {
        this.overlay = false;
        this.currentIndex = null;
      }
    },

    goBefore() {
      if (this.currentIndex !== 0) {
        this.currentIndex--;
      }
    },

    goFront() {
      if (this.currentIndex <= this.viewGallery.length - 1) {
        this.currentIndex++;
      }
    },

    clickPic(gallery, index) {
      // console.log(index);
      this.overlay = true;
      this.viewGallery = gallery;
      this.currentIndex = index;
    },
    goToArticle(title) {
      window.location.href = "/ourwork/" + title;
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/variables.sass'
.overlayRow
  max-width: 700px
  padding-bottom: 0 !important
  .picRow
    width: 500px
    margin-top: 10px
    +mobile
      width: 100%
    .picture
      +desktop
        max-width: 500px
      +mobile
        max-width: 250px

.article-container
  max-width: 900px
  margin: 0 auto
  padding: 0 20px
  .header-title
    margin: 50px 0 20px 0
    h1
      color: $primary-orange
      font-family: 'RobotoSlab'
      margin-bottom: 40px
  .content-wrapper
    text-align: left
    .title-container
      margin-top: 50px
      h2
        font-size: 32px
        line-height: 36px
        color: $primary-orange
        margin-bottom: 0
        +mobile
          font-size: 24px
          line-height: 28px
      p
        color: $primary-grey
        font-weight: bold
        font-size: 16px
        line-height: 22px
        margin-bottom: 0
        +mobile
          font-size: 14px
          line-height: 18px
      .date-tags-wrapper
        display: flex
        align-items: center
        .tags-container
          margin-left: 20px
          p
            color: $primary-orange
    .bottom-container
      margin: 10px 0
      .top-image
        margin: 40px 0 40px 0
        display: block
        height: 400px
        text-align: center
        width: 100%
        +mobile
          height: auto
          margin: 20px 0 20px 0
        img
          max-width: 100%
          max-height: 100%
          +mobile
            width: 100%
      .text-container
        ::v-deep span
          color: $primary-grey !important
          font-size: 16px
          line-height: 22px
          margin-bottom: 0 !important
          +mobile
            font-size: 14px
            line-height: 18px
        ::v-deep p
          color: $primary-grey !important
          font-size: 16px
          line-height: 22px
          margin-bottom: 0 !important
          +mobile
            font-size: 14px
            line-height: 18px
        ::v-deep strong
          color: $primary-grey !important
          font-size: 16px
          line-height: 22px
          margin-bottom: 0 !important
          +mobile
            font-size: 14px
            line-height: 18px
      .gallery-container
        display: flex
        justify-content: space-evenly
        width: 100%
        flex-wrap: wrap
        align-items: flex-start
        img
          cursor: pointer
          // width: 33%
          margin: 10px 0
          max-height: 250px
  .separator
    color: $primary-grey
    width: 80%
    margin: 30px auto
  .other-articles
    display: flex
    flex-direction: column
    justify-content: center
    margin-bottom: 50px
    .other-header
      color: $primary-orange
    .articles-wrapper
      display: flex
      justify-content: space-evenly
      margin: 15px 0 20px
      flex-wrap: wrap
      .article
        cursor: pointer
        text-align: left
        max-width: 250px
        width: 250px
        margin: 20px auto
        +mobile
          max-width: 300px
          width: 300px
        .mainImg
          width: 240px
          height: 240px
          max-width: 240px
          max-height: 240px
          +mobile
            max-width: 300px
            max-height: 300px
            height: 300px
            width: 300px
        h3
          color: $primary-grey
          font-size: 18px
          line-height: 22px

.tags-container
  display: flex
  align-items: center
  img
    width: 23px
    height: 26px
    margin-bottom: 5px
    margin-right: 5px
    margin-left: -3px
  p
    font-size: 16px
    line-height: 22px
    font-weight: bold
    color: $primary-orange
    margin: 0
</style>
