<template>
  <v-container fill-height>
    <v-row class="justify-center" no-gutters>
      <v-card class="card justify-center">
        <v-card-text>
          <h3 class="display-1 text--primary">
            Edit Blog dated {{ this.blog.date }}
          </h3>
          <v-form @submit.prevent="onFormSubmit" ref="form">
            <v-row justify="center">
              <v-col cols="8">
                <label class="text-h5 text--primary text-start">Title</label>
                <v-text-field
                  v-model="blog.title"
                  outlined
                  required
                ></v-text-field>

                <label class="text-h5 text--primary text-start">Content</label>
                <vue-editor
                  :editor-toolbar="customToolbar"
                  id="editor"
                  v-model="blog.content"
                >
                </vue-editor>

                <v-layout wrap class="testtt">
                  <label class="text-h5 text--primary text-start"
                    >Currently Present Tags</label
                  >
                  <v-combobox
                    multiple
                    v-model="tags"
                    chips
                    outlined
                    append-icon
                    deletable-chips
                    class="tagOptions"
                  ></v-combobox>
                  <label class="text-h5 text--primary text-start"
                    >Add Tag (Press Enter After Typing Tag)</label
                  >
                  <v-flex xs12>
                    <v-combobox
                      outlined
                      v-model="select"
                      append-icon
                      chips
                      deletable-chips
                      class="tagInput"
                    >
                    </v-combobox>
                  </v-flex>
                </v-layout>

                <v-container>
                  <v-row justify="start">
                    <v-col justify="start">
                      <label
                        class="text-h5 lab text--primary labels text-center"
                        >Add Thumbnail Photo (Only 1 Picture)</label
                      >
                      <input
                        type="file"
                        ref="input1"
                        @change="previewImage3"
                        accept="image/*"
                        multiple
                      />
                    </v-col>
                  </v-row>
                </v-container>
                <v-container class="form-group" v-if="thumbnail.length !== 0">
                  <v-row justify="center">
                    <v-col cols="12">
                      <v-card>
                        <v-img :src="thumbnail[0].url" class="preview"></v-img>
                        <v-btn
                          @click.prevent="onDelete3(thumbnail[0].reference)"
                          >Delete</v-btn
                        >
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container>
                  <v-row justify="start">
                    <v-col justify="start">
                      <label
                        class="text-h5 lab text--primary labels text-center"
                        >Add Top Photo (Only 1 Picture)</label
                      >
                      <input
                        type="file"
                        ref="input1"
                        @change="previewImage"
                        accept="image/*"
                        multiple
                      />
                    </v-col>
                  </v-row>
                </v-container>
                <v-container class="form-group" v-if="mainImage.length !== 0">
                  <v-row justify="center">
                    <v-col cols="12">
                      <v-card>
                        <v-img :src="mainImage[0].url" class="preview"></v-img>
                        <v-btn @click.prevent="onDelete(mainImage[0].reference)"
                          >Delete</v-btn
                        >
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container>
                  <v-row justify="start">
                    <v-col justify="start">
                      <label
                        class="text-h5 lab text--primary labels text-center"
                        >Add Gallery Photos</label
                      >
                      <input
                        type="file"
                        ref="input1"
                        @change="previewImage2"
                        accept="image/*"
                        multiple
                      />
                    </v-col>
                  </v-row>
                </v-container>
                <v-container class="form-group" v-if="imageList.length !== 0">
                  <v-row justify="center">
                    <v-col
                      cols="4"
                      v-for="image in imageList"
                      :key="image.reference"
                    >
                      <v-card>
                        <v-img :src="image.url" class="preview"></v-img>
                        <v-btn @click.prevent="onDelete2(image.reference)"
                          >Delete</v-btn
                        >
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <v-card-actions class="justify-center">
              <v-btn
                :disabled="allowButton"
                type="submit"
                class="btn btn-primary btn-block"
                >Finish Editing</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "../main";
import firebase from "firebase";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      select: "",
      mainImage: [],
      tags: [],
      imageList: [],
      thumbnail: [],
      imageData: null,
      imageData2: null,
      imageData3: null,
      originalImageList: [],
      originalMainImage: [],
      deleteMainRefAfter: [],
      deleteRefAfter: [],
      blog: {
        title: "",
        content: "",

        date: "",
        tag: [],
        mainImage: {},
        gallery: [],
      },
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
    };
  },
  computed: {
    allowButton() {
      return !(
        this.blog.title.length > 0 &&
        this.blog.content.length > 0 &&
        this.select.length > 0
      );
    },
  },
  async created() {
    let dbRef = db.collection("blogs").doc(this.$route.params.id);
    await dbRef
      .get()
      .then((doc) => {
        this.blog = doc.data();
        this.imageList = doc.data().gallery;
        this.originalImageList = doc.data().gallery;
        // console.log(this.blog.mainImage);
        if (this.blog.mainImage.length !== 0) {
          this.originalMainImage.push(this.blog.mainImage);
          this.mainImage.push(this.blog.mainImage);
        }
        if (this.blog.thumbnail.length !== 0) {
          this.thumbnail.push(this.blog.thumbnail);
        }
        if (this.blog.tag !== null) this.select = this.blog.tag;
        else this.select = "";
        // console.log(this.blog);
      })
      .catch((error) => {
        console.error(error);
      });
    let dbRef2 = db.collection("etc").doc("tags");
    await dbRef2
      .get()
      .then((doc) => {
        this.tags = doc.data().tags;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    onFormSubmit() {
      if (this.mainImage.length !== 0) this.blog.mainImage = this.mainImage[0];
      else this.blog.mainImage = [];

      if (this.thumbnail.length !== 0) this.blog.thumbnail = this.thumbnail[0];
      else this.blog.thumbnail = [];

      if (this.imageList.length !== 0) this.blog.gallery = this.imageList;
      else this.blog.gallery = [];

      for (this.ref of this.deleteRefAfter) {
        const storageRef = firebase.storage().ref();
        var deletedRef = storageRef.child(this.ref);
        deletedRef
          .delete()
          .then(function () {
            console.log("DELETED PHOTO");
            // File deleted successfully
          })
          .catch(function (error) {
            // Uh-oh, an error occurred!
            console.error(error);
          });
      }
      for (this.ref of this.deleteMainRefAfter) {
        const storageRef2 = firebase.storage().ref();
        var deletedRef2 = storageRef2.child(this.ref);
        deletedRef2
          .delete()
          .then(function () {
            console.log("DELETED PHOTO");
            // File deleted successfully
          })
          .catch(function (error) {
            // Uh-oh, an error occurred!
            console.error(error);
          });
      }

      if (!this.tags.includes(this.select)) {
        this.tags.push(this.select);
      }
      db.collection("etc")
        .doc("tags")
        .update({ tags: this.tags })
        .then(() => {
          console.log("Tags Updated!");
        })
        .catch((error) => {
          console.error(error);
        });

      this.blog.tag = this.select;
      db.collection("blogs")
        .doc(this.$route.params.id)
        .update(this.blog)
        .then(() => {
          console.log("Blog successfully updated!");
          this.$router.push("/admin/blogList");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    previewImage(event) {
      this.uploadValue = 0;
      // console.log(event.target.files.length);
      if (event.target.files.length > 1) {
        console.log("Too many files");
      } else {
        this.imageData = event.target.files;
        this.onUpload();
      }
    },
    onUpload() {
      for (this.data of this.imageData) {
        const name = Date.now() + this.data.name;
        const storageRef = firebase.storage().ref(name).put(this.data);
        storageRef.on(
          `state_changed`,
          (snapshot) => {
            this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.error(error.message);
          },
          () => {
            this.uploadValue = 100;
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              if (this.mainImage.length !== 0) {
                const StorageRef = firebase.storage().ref();
                var deletedRef = StorageRef.child(this.mainImage[0].reference);
                deletedRef
                  .delete()
                  .then(function () {
                    console.log(
                      "DELETED " + this.mainImage[0].reference + " !"
                    );
                    // File deleted successfully
                  })
                  .catch(function (error) {
                    // Uh-oh, an error occurred!
                    console.error(error);
                  });
              }
              this.mainImage = [];
              this.mainImage.push({ reference: name, url: url });
            });
          }
        );
      }
    },
    onDelete(reference) {
      var deleteOnFB = true;
      for (let i = this.mainImage.length - 1; i >= 0; i--) {
        if (this.mainImage[i].reference === reference) {
          this.mainImage.splice(i, 1);
        }
      }
      for (let i = 0; i < this.originalMainImage.length; i++) {
        if (this.originalMainImage[i].reference === reference) {
          deleteOnFB = false;
          this.deleteMainRefAfter.push(reference);
        }
      }
      this.mainImage = [];

      if (deleteOnFB) {
        const storageRef = firebase.storage().ref();
        var deletedRef = storageRef.child(reference);
        deletedRef
          .delete()
          .then(function () {
            console.log("DELETED " + reference + " !");
            // File deleted successfully
          })
          .catch(function (error) {
            // Uh-oh, an error occurred!
            console.error(error);
          });
      }
    },
    previewImage2(event) {
      this.uploadValue = 0;
      // console.log(event.target.files.length);
      this.imageData2 = event.target.files;
      this.onUpload2();
    },
    onUpload2() {
      for (this.data of this.imageData2) {
        const name = Date.now() + this.data.name;
        const storageRef = firebase.storage().ref(name).put(this.data);
        storageRef.on(
          `state_changed`,
          (snapshot) => {
            this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.error(error.message);
          },
          () => {
            this.uploadValue = 100;
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              this.imageList.push({ reference: name, url: url });
            });
          }
        );
      }
    },
    onDelete2(reference) {
      var deleteOnFB = true;
      // console.log(this.imageList);
      // console.log(this.originalImageList);
      for (let i = this.imageList.length - 1; i >= 0; i--) {
        if (this.imageList[i].reference === reference) {
          this.imageList.splice(i, 1);
        }
      }
      for (let i = 0; i < this.originalImageList.length; i++) {
        console.log(
          "Reference: " +
            reference +
            ", OriginalList: " +
            this.originalImageList[i].reference
        );
        if (this.originalImageList[i].reference === reference) {
          deleteOnFB = false;
          this.deleteRefAfter.push(reference);
        }
      }
      if (deleteOnFB) {
        const storageRef = firebase.storage().ref();
        var deletedRef = storageRef.child(reference);
        deletedRef
          .delete()
          .then(function () {
            console.log("DELETED " + reference + " !");
            // File deleted successfully
          })
          .catch(function (error) {
            // Uh-oh, an error occurred!
            console.error(error);
          });
      }
    },
    previewImage3(event) {
      this.uploadValue = 0;
      // console.log(event.target.files.length);
      if (event.target.files.length > 1) {
        alert.log("Too many files");
      } else {
        this.imageData3 = event.target.files;
        this.onUpload3();
      }
    },
    onUpload3() {
      for (this.data of this.imageData3) {
        const name = Date.now() + this.data.name;
        const storageRef = firebase.storage().ref(name).put(this.data);
        storageRef.on(
          `state_changed`,
          (snapshot) => {
            this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.error(error.message);
          },
          () => {
            this.uploadValue = 100;
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              if (this.thumbnail.length !== 0) {
                const StorageRef = firebase.storage().ref();
                var deletedRef = StorageRef.child(this.thumbnail[0].reference);
                deletedRef
                  .delete()
                  .then(function () {
                    console.log(
                      "DELETED " + this.mainImage[0].reference + " !"
                    );
                    // File deleted successfully
                  })
                  .catch(function (error) {
                    // Uh-oh, an error occurred!
                    console.error(error);
                  });
              }
              this.thumbnail = [];
              this.thumbnail.push({ reference: name, url: url });
            });
          }
        );
      }
    },
    onDelete3(reference) {
      this.thumbnail = [];
      // console.log(this.imageList);
      const storageRef = firebase.storage().ref();
      var deletedRef = storageRef.child(reference);
      deletedRef
        .delete()
        .then(function () {
          console.log("DELETED " + reference + " !");
          // File deleted successfully
        })
        .catch(function (error) {
          // Uh-oh, an error occurred!
          console.error(error);
        });
    },
  },
};
</script>
<style lang="sass" scoped>
@import '../sass/variables.sass'
.card
  +desktop
    width: 60%
  +mobile
    width: 100%
.labels
  margin-bottom: 1%
.lab
  margin-bottom: 5%

.preview
  height: 175px

.testtt
  margin-top: 10%

.tagOptions
  .v-chip
    background-color: blue !important
</style>