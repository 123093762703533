<template lang="pug">
#contactus
  .wrapper
    .banner-section
      img.desktop(src="../assets/images/contact-us/banner.png", loading="lazy")
      img.mobile(src="../assets/images/contact-us/banner.png", loading="lazy")
    .bottom-container
      h1 Contact Details
      .information-wrapper
        h2 My Pets’ Haven
        .details
          img(src="../assets/images/general/clock.svg")
          p 10.00am - 8.00pm every day
        .details
          img(src="../assets/images/general/phone-g.svg")
          p +6019-6632828 (Aileen)
        .details
          img(src="../assets/images/general/mail-g.svg")
          a(href="mailto:lovemypetshaven@gmail.com") lovemypetshaven@gmail.com
        .details.last
          img(src="../assets/images/general/map-marker-g.svg")
          p A-2-G, Galleri Klang Sentral, Jalan Klang Sentral 15/KU5, <br>41050 Klang, Selangor, Malaysia.
      .maps-container
        iframe(
          height="400",
          frameborder="0",
          style="border: 0",
          src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJKcUR4eRTzDERbyFekMsG-gs&key=AIzaSyAqRCzhyHZRn9B9yjGzIheb_d3s8xz8AoQ",
          allowfullscreen
        )
      .connect-container
        h2 Connect with us:
        p Instagram: #[a(href="https://www.instagram.com/mypetshaven/?hl=en", target="__blank") @mypetshaven]
        p Facebook: #[a(href="https://www.facebook.com/mypetshaven/", target="__blank") My Pets Haven]
      .form-container
        form(@submit.prevent="enquireEmail")
          h2 Got a question? Let us know below:
          .questions
            p Name:
            input(type="text", maxlength="30", v-model="name")
            span.error {{ nameErr }}
          .questions
            p Email:
            input(type="email", v-model="email")
            span.error {{ emailErr }}
          .questions
            p Mobile Number:
            input(type="number", v-model="phoneNumber")
            span.error {{ numberErr }}
          .questions
            p Question:
            textarea(v-model="question")
            span.error {{ questionErr }}
          .button-container
            button(type="submit") Submit
</template>
<script>
import axios from "axios";

export default {
  name: "ContactUs",
  data() {
    return {
      name: "",
      nameErr: "",
      email: "",
      emailErr: "",
      phoneNumber: "",
      numberErr: "",
      question: "",
      questionErr: "",
    };
  },
  metaInfo: {
    title: "Contact Us",
    meta: [
      {
        name: "description",
        content:
          "Contact us at our number or pay a visit to our shelter. We are open everyday from 10.00am-8.00pm, including public holidays.",
      },
      {
        name: "keywords",
        content:
          "mypetshaven, dogs, adopt, cats, adoption, shelter, volunteer, puppies, kittens, neuter, rescue, animals, nurture, injure, donate, pets, abandon, haven, aileen, setia, alam, strays, companion, declaw, my, pets, haven,",
      },
    ],
  },
  methods: {
    async enquireEmail() {
      document.getElementById("loading-screen").style.display = "flex";
      this.nameErr = "";
      this.emailErr = "";
      this.numberErr = "";
      this.questionErr = "";

      if (this.name.trim() === "") {
        this.nameErr = "Please enter a valid name.";
      }

      if (this.email.trim() === "") {
        this.emailErr = "Please enter a valid email.";
      }

      if (this.phoneNumber.trim() === "") {
        this.numberErr = "Please enter a valid Phone Number.";
      }

      if (this.question.trim() === "") {
        this.questionErr = "Please enter a valid question.";
      }

      if (
        this.nameErr.length === 0 &&
        this.emailErr.length === 0 &&
        this.questionErr.length === 0 &&
        this.numberErr.length === 0
      ) {
        var body = {
          name: this.name.trim(),
          email: this.email.trim(),
          phoneNumber: this.phoneNumber.trim(),
          message: this.question.trim(),
        };
        try {
          axios
            .post("https://smtp-pog-request.onrender.com/enquiry", body, {
              headers: {},
            })
            .then(() => {
              // console.log(response);
              location.reload();
            });
        } catch (e) {
          console.error(e);
          document.getElementById("loading-screen").style.display = "none";
        }
      } else {
        document.getElementById("loading-screen").style.display = "none";
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/variables.sass'
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type=number]
  -moz-appearance: textfield

.maps-container
  iframe
    width: 500px
    +mobile
      width: 100%

#contactus
  .banner-section
    width: 100%
    img
      width: 100%
  .bottom-container
    text-align: left
    max-width: 1100px
    padding: 0 80px
    margin: 20px auto 30px auto
    +mobile
      padding: 0 20px
    h1
      margin-bottom: 20px
      color: $primary-orange
      font-family: 'RobotoSlab'
    .information-wrapper
      h2
        color: $primary-grey
      .details
        display: flex
        text-align: left
        margin: 10px 0
        &.last
          img
            width: 28px
            height: 24px
        p
          color: $primary-grey
          margin: 0 0 0 10px
        a
          margin: 0 0 0 10px
        &:nth-child(1)
          margin: 0
          img
            margin-right: 5px
            p
              margin: 0
              color: #fff
    .connect-container
      margin: 20px 0
      h2
        color: $primary-orange
        margin-bottom: 10px
        font-size: 22px
        line-height: 26px
        font-weight: 600
      p
        color: $primary-grey
        margin-bottom: 0
    .form-container
      margin: 20px 0
      form
        h2
          color: $primary-orange
          margin-bottom: 10px
          font-size: 22px
          line-height: 26px
          font-weight: 600
        .questions
          margin: 0 0 10px 0
          display: flex
          flex-direction: column
          span
            &.error
              background-color: #fff !important
              color: red
              font-size: 14px
              line-height: 18px
          p
            color: $primary-grey
            margin-bottom: 0
          input
            border: 1px solid $primary-grey
            color: $primary-grey
            padding: 5px
            width: 200px
            &:active, &:focus
              border: 1px solid $primary-orange
              outline: 1px solid $primary-orange
          textarea
            border: 1px solid $primary-grey
            width: 350px
            height: 100px
            color: $primary-grey
            padding: 5px
            resize: none
            &:active, &:focus
              border: 1px solid $primary-orange
              outline: 1px solid $primary-orange
        .button-container
          margin: 20px 0 20px 0
          button
            padding: 5px 20px
            background-color: #fff
            border: 3px solid $primary-orange
            color: $primary-orange
            font-weight: bold
            font-family: 'RobotoSlab'
            &:hover
              background-color: $primary-orange
              color: #fff
</style>
