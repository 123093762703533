<template>
  <v-container class="mainContainer" fluid ma-0 pa-0>
    <!-- <v-img
      :lazy-src="require('../assets/images/adopt/banner.png')"
      max-height="600"
      max-width="100%"
      background-position="bottom bottom"
      :src="require('../assets/images/adopt/banner.png')"
      class="desktop"
    ></v-img>
    <v-img
      :lazy-src="require('../assets/images/adopt/banner.png')"
      max-height="400"
      max-width="100%"
      :src="require('../assets/images/adopt/banner.png')"
      class="mobile"
    ></v-img> -->
    <v-overlay :value="overlay">
      <v-card
        color="white"
        class="cardder text-center popup rounded-lg d-flex align-center"
      >
        <v-card-text>
          <h1 class="overlaytext name">
            Adoption Enquiry<br class="desktop" />
            Sent!
          </h1>
          <!-- <h1 class="overlaytext name top">Confirmed!</h1> -->
          <h1 class="overlaytext bot">
            Thank you for enquiring and we will get back to you soon!
          </h1>
          <v-btn
            raised
            rounded
            class="white--text"
            color="rgb(255,151,133)"
            to="/"
            >Back to Home</v-btn
          >
        </v-card-text>
      </v-card>
    </v-overlay>
    <div class="banner-section">
      <img
        src="../assets/images/adopt/banner.png"
        loading="lazy"
        class="desktop"
      />
      <img
        src="../assets/images/adopt/banner.png"
        loading="lazy"
        class="mobile"
      />
    </div>
    <v-container class="page-cont">
      <v-row align="start" justify="start" class="desk" no-gutters>
        <h1>Adoption Policy</h1>
      </v-row>
      <v-row align="start" justify="start" class="desk despacito" no-gutters>
        <p class="text">
          To adopt from us, you must agree to neuter or spay your newly adopted
          pet. We do not charge adoption fees but we charge a neutering/spaying
          fee of RM300 for dogs and RM180 for cats. Spaying refers to the
          removal of the reproductive organs of female dogs and cats, while
          neutering is the removal of the testicles in male dogs and cats hence
          the surgery performed would render them unable to reproduce. The fee
          will serve as a deposit that will be used to neuter or spay your newly
          adopted pet once it is old enough. Once your pet has reached puberty
          or the age of 6 months, all you have to do is to notify us then we
          will arrange an appointment for you at our associated veterinary
          clinic where the procedure will be done. There will be no additional
          charges incurred.
          <br /><br />
          For adopters who have a regular vet or who live far away from our
          associated veterinary clinic, you have the option to neuter or spay
          your pet on your own at a local veterinary clinic. We will refund 100%
          of the deposit collected once proof of receipt and a picture of your
          pet’s surgical wound have been received. We will still collect the
          deposit upon adoption to ensure that you will fulfill our adoption
          policy to neuter or spay your pet. We wish to avoid any accidental
          pregnancies as the population of the homeless animals on the streets
          is ever increasing.
          <br /><br />
          Neutering or spaying is one of the key methods to control the stray
          animal population in Malaysia. Unfortunately, the authorities play
          little to no part in curbing the issue of the increasing population of
          the strays hence we must do our part in controlling the number of
          homeless animals on our streets by neutering or spaying our own pet.
          For more information on the benefits of neutering and spaying your pet
          towards their health and towards our society, you can visit this
          <a
            href="https://pets.webmd.com/reasons-spay-neuter-pet#2"
            target="_blank"
            >link</a
          >
          attached.
        </p>
      </v-row>
      <v-row align="start" justify="start" class="desk second" no-gutters>
        <h1>Adoption Instructions</h1>
      </v-row>
      <v-row align="start" justify="start" class="desk despacito" no-gutters>
        <p class="text">1. Choose the animal that you wish to adopt.</p>
        <p class="text">
          2. Call +6019-6632828 (Aileen) to let us know and be prepared for a
          short interview about yourself on the phone. We do this to ensure that
          our animals will be in good hands when adopted!
        </p>
        <p class="text">
          3. Arrange an appointment on the phone to pick up your pet.
        </p>
        <p class="text">
          4. We allow walk-in adoptions as well! We are open every day from
          9.00am-8.00pm so feel free to visit us any day of the week.
        </p>
      </v-row>
      <v-row align="start" justify="start" class="desk second" no-gutters>
        <h1>Adopt, dont shop!</h1>
      </v-row>
      <div class="banner-section">
        <img
          src="../assets/images/adopt/header.png"
          loading="lazy"
          class="desktop"
        />
        <img src="../assets/images/adopt/9.png" loading="lazy" class="mobile" />
        <img
          src="../assets/images/adopt/10.png"
          loading="lazy"
          class="mobile"
        />
        <img
          src="../assets/images/adopt/11.png"
          loading="lazy"
          class="mobile"
        />
      </div>
      <v-row justify="start" fluid class="desk second" no-gutters>
        <v-col cols="12" justify="start">
          <h1>FAQs</h1>
          <v-list>
            <v-list-group color="#FF9785" no-action class="border pl-0">
              <template v-slot:activator>
                <v-list-item-content class="title text-h6 text pl-0">
                  1. Can we adopt if we choose not to neuter/spay our adopted
                  pet?
                </v-list-item-content>
              </template>

              <v-list-item class="text-h6 text content pl-0">
                <v-list-item-content class="descr">
                  It is strictly our policy to neuter/spay your pet if you
                  choose to adopt from us. We strongly recommend you to
                  sterilise your pet as it is a procedure that brings many
                  health benefits to your pet. Besides, there is no need to
                  worry about menstruation and accidental pregnancies.
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-group color="#FF9785" no-action class="border pl-0">
              <template v-slot:activator>
                <v-list-item-content class="title text-h6 text pl-0">
                  2. Can we adopt if we live in other states in Malaysia?
                </v-list-item-content>
              </template>

              <v-list-item class="text-h6 text content pl-0">
                <v-list-item-content class="descr">
                  Yes you can, provided you are able to transport the pet from
                  our centre to your home! We do not provide any transport
                  services to send the pet to other states. However, you might
                  be able to find a pet taxi that does!
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-group color="#FF9785" no-action class="border pl-0">
              <template v-slot:activator>
                <v-list-item-content class="title text-h6 text pl-0">
                  3. Does My Pets’ Haven have any small or pedigree breeds that
                  are up for adoption?
                </v-list-item-content>
              </template>

              <v-list-item class="text-h6 text content pl-0">
                <v-list-item-content class="descr">
                  It is very rare that we have any small or pedigree breeds that
                  are up for adoption as most of our animals are rescued hence
                  the dogs usually grow up to medium size. It is only when
                  owners decide that they cannot keep their pets anymore that we
                  might have them up for adoption.
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-group color="#FF9785" no-action class="border pl-0">
              <template v-slot:activator>
                <v-list-item-content class="title text-h6 text pl-0">
                  4. What do we do if we aren’t able to care for the adopted pet
                  anymore?
                </v-list-item-content>
              </template>

              <v-list-item class="text-h6 text content pl-0">
                <v-list-item-content class="descr">
                  Animal abandonment is something we do not hope for with our
                  adopters but we completely understand that there might come
                  some unforeseen circumstances where you would have to give up
                  your pet so we would be willing to accept them back to our
                  shelter.
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-group color="#FF9785" no-action class="border pl-0">
              <template v-slot:activator>
                <v-list-item-content class="title text-h6 text pl-0">
                  5. Can we adopt if we live in a different country?
                </v-list-item-content>
              </template>

              <v-list-item class="text-h6 text content pl-0">
                <v-list-item-content class="descr">
                  Unfortunately no as we are unable to transport the pet to
                  another country.
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-group color="#FF9785" no-action class="border pl-0">
              <template v-slot:activator>
                <v-list-item-content class="title text-h6 text pl-0">
                  6. What are the benefits of neutering/spaying our pets?
                </v-list-item-content>
              </template>

              <v-list-item class="text-h6 text content pl-0">
                <v-list-item-content class="descr">
                  There are many health and behavioural benefits for your pets.
                  Here is an article to learn more about neutering/spaying your
                  pets.<br />
                  <a
                    href="https://www.aspca.org/pet-care/general-pet-care/spayneuter-your-pet"
                    target="_blank"
                    >https://www.aspca.org/pet-care/general-pet-care/spayneuter-your-pet</a
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
    <v-row
      class="link"
      fluid
      ma-0
      pa-0
      no-gutters
      justify="center"
      align="center"
    >
      <p class="bigTitle">Enquire about a specific pet</p>
      <v-card class="swag">
        <v-card-text>
          <v-form
            @submit.prevent="onFormSubmit"
            ref="form"
            v-model="isFormValid"
          >
            <v-row no-gutters>
              <v-col class="field" cols="12" sm="6">
                <label class="text-start labels">Your Name: </label>
                <v-text-field
                  class="textbox"
                  outlined
                  background-color="white"
                  v-model="enquiry.name"
                  required
                ></v-text-field>
              </v-col>
              <v-col class="field" cols="12" sm="6">
                <label class="text-start labels">Pet's Name: </label>
                <v-text-field
                  background-color="white"
                  class="textbox"
                  outlined
                  v-model="enquiry.animalName"
                  required
                ></v-text-field>
              </v-col>
              <v-col class="field" cols="12" sm="6">
                <label class="text-start labels">E-mail: </label>
                <v-text-field
                  background-color="white"
                  class="textbox"
                  outlined
                  v-model="enquiry.email"
                  :rules="emailRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col class="field" cols="12" sm="6">
                <label class="text-start labels">Mobile Number: </label>
                <v-text-field
                  background-color="white"
                  class="textbox"
                  outlined
                  v-model="enquiry.phoneNumber"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <v-col class="field" cols="12">
                <label class="text-start labels"
                  >What else would you like to know about?</label
                >
                <v-textarea
                  background-color="white"
                  outlined
                  class="textbox"
                  v-model="enquiry.reason"
                  required
                  auto-grow
                >
                </v-textarea>
              </v-col>
              <v-row class="justify-center">
                <v-btn
                  type="submit"
                  raised
                  rounded
                  class="white--text btn"
                  color="#FF9785"
                  :disabled="
                    enquiry.name.trim() === '' ||
                    enquiry.email.trim() === '' ||
                    enquiry.phoneNumber.trim() === '' ||
                    enquiry.animalName.trim() === '' ||
                    enquiry.reason.trim() === ''
                  "
                  >Submit</v-btn
                >
              </v-row>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>


<script>
import { db } from "../main";
import axios from "axios";
export default {
  data() {
    return {
      isFormValid: false,
      animal: null,
      enquiry: {
        name: "",
        email: "",
        phoneNumber: "",
        reason: "",
        animalName: "",
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      overlay: false,
    };
  },
  metaInfo: {
    title: "Adoption Policy",
    meta: [
      {
        name: "description",
        content:
          "Read on our adoption policy to understand better before choosing to adopt a pet. You may leave an enquiry as well.",
      },
      {
        name: "keywords",
        content:
          "mypetshaven, dogs, adopt, cats, adoption, shelter, volunteer, puppies, kittens, neuter, rescue, animals, nurture, injure, donate, pets, abandon, haven, aileen, setia, alam, strays, companion, declaw",
      },
    ],
  },
  computed: {},
  methods: {
    onFormSubmit(event) {
      // console.log(this.animal);
      event.preventDefault();
      document.getElementById("loading-screen").style.display = "flex";
      this.enquiry.date = Date.now();
      db.collection("adoptionEnquiries")
        .add(this.enquiry)
        .then(() => {
          var body = {
            name: this.enquiry.name.trim(),
            email: this.enquiry.email.trim(),
            phoneNumber: this.enquiry.phoneNumber.trim(),
            petName: this.enquiry.animalName.trim(),
            message: this.enquiry.reason.trim(),
          };
          axios
            .post("https://smtp-pog-request.onrender.com/adopt", body, {
              headers: {},
            })
            .then(() => {
              document.getElementById("loading-screen").style.display = "none";
              this.overlay = true;
              // location.reload();
            });
        })
        .catch((error) => {
          console.error(error);
          document.getElementById("loading-screen").style.display = "none";
        });
    },
  },
};
</script>
<style lang="sass" scoped>
@import '../sass/variables.sass'
h1
  font-family: 'RobotoSlab' !important
.mainContainer
  background: white
  min-height: 100%

.despacito
  margin-bottom: 36px
.page-cont
  max-width: 1100px
  padding: 0 20px
  margin: 30px auto 30px auto

  +mobile
    padding: 0 20px
    margin-top: 20px !important

.banner-section
  width: 100%
  margin-bottom: 20px
  align-items: flex-start
  +mobile
    display: flex
    flex-wrap: wrap
  img
    width: 100%

.btn-primary
  margin-right: 12px

.labels
  font-size: 18px
  font-weight: bold
  margin-bottom: 1%
  white-space: default
  color: $primary-orange
  font-family: 'RobotoSlab' !important

.desk
  text-align: left

.text
  color: $primary-grey
  font-size: 16px
  line-height: 22px
  +mobile
    font-size: 14px
    line-height: 18px

h1
  font-size: 32px
  line-height: 36px
  color: $primary-orange
  margin-bottom: 10px
  +mobile
    font-size: 24px
    line-height: 28px

p
  color: $primary-grey
  font-size: 16px
  line-height: 22px
  +mobile
    font-size: 14px
    line-height: 18px

.title
  color: $primary-orange
  font-family: 'RobotoSlab' !important

.bigDate
  height: 500px

.overlaytext
  font-size: 32px
  line-height: 36px
  font-family: 'RobotoSlab'

.bot
  line-height: 1
  color: $primary-grey !important
  margin-bottom: 10%
  font-size: 18px
  line-height: 22px

.name
  color: $primary-orange !important

.cardder
  +mobile
    width: 300px
    height: 400px
  +desktop
    width: 350px
    height: 350px

.border
  border: 1px solid $primary-orange
  margin-bottom: 2%

.deskr
  white-space: normal
  text-align: start
  margin-left: 0px
  padding-left: 0px

.content
  margin-left: 16px

.descr
  color: $primary-grey !important
  line-height: 1.2em
  font-size: 1.0em
  text-align: start !important
  font-family: 'RobotoSlab' !important

.link
  display: flex
  flex-direction: column
  margin-top: 20px
  background-image: url("https://picsum.photos/id/10/1500/400")
  background-repeat: no-repeat
  background-size: 100% 100%
  padding: 40px 0 50px
  +mobile
    background-image: url("https://picsum.photos/id/10/600/400")

.swag
  width: 85%
  max-width: 1100px
  height: 70%
  background-color: $primary-lighto !important
  +mobile
    padding: 0 20px

.bigTitle
  font-size: 50px
  line-height: 50px
  font-family: 'RobotoSlab' !important
  font-weight: bold
  color: $primary-orange
  margin-bottom: 20px
  +mobile
    font-size: 30px
    line-height: 34px
    padding: 0 20px

.field
  padding-left: 1% !important
  padding-right: 1% !important
</style>