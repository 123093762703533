<template lang="pug">
.our-work
  .banner-section
    img(src="../assets/images/our-work/banner.png", loading="lazy")
    //- img.mobile(src="https://via.placeholder.com/700x500/333333")
  .work-container
    .header-title
      h1 OUR FURBALL UPDATES
    .content-wrapper
      .content-left(v-if="filteredUpdates.length === 0")
        .news-box(v-for="update in furryUpdates")
          .news-title
            h1 {{ update.title }}
          .date-tags-container
            .date
              p {{ update.date }}
            .tags
              img(src="../assets/images/general/tags-o.png")
              p {{ update.tag }}
          .news-info
            .news-image
              img(:src="update.thumbnail.url")
            .news-content
              .news-text
                p(v-html="update.content")
              button.read-button(@click="goToArticle(update.id)")
                p Read more
                //- img(src="../assets/images/general/arrow-line-o.svg")
        .last-container(v-if="finalPage == true")
          hr
          h2 You have reached the end of Our Works page. <br class="desktop">Do stay tuned for more future updates!

      .content-left(v-else)
        .news-box(v-for="filtered in filteredUpdates")
          .news-title
            h1 {{ filtered.title }}
          .date-tags-container
            .date
              p {{ filtered.date }}
            .tags
              img(src="../assets/images/general/tags-o.png")
              p {{ filtered.tag }}
          .news-info
            .news-image
              img(:src="filtered.thumbnail.url")
            .news-content
              .news-text
                p(v-html="filtered.content")
              button.read-button(@click="goToArticle(filtered.id)")
                p Read more
                //- img(src="../assets/images/general/arrow-line-o.svg")

        .last-container(v-if="finalPage == true")
          hr
          h2 You have reached the end of our Our Works page. <br>Do stay tuned for more future updates!

      .content-right
        .content-tags
          .top-box
            h3 Categories
          .bottom-box
            button(v-for="tag in tags", @click="tagPress(tag)")
              h3 {{ tag }}
            button(@click="tagPress('all')")
              h3 All Updates
</template>

<script>
import { db } from "../main";
export default {
  name: "OurWork",
  data() {
    return {
      tags: [],
      lastDocument: "",
      filteredUpdates: [],
      furryUpdates: [],
      articleID: "",
      finalPage: false,
      loading: false,
    };
  },
  metaInfo: {
    title: "Our Work",
    meta: [
      {
        name: "description",
        content:
          "Read about our rescue work and what we do. See the transformation of our animals from when they were rescued and the difference that we can achieve.",
      },
      {
        name: "keywords",
        content:
          "mypetshaven, dogs, adopt, cats, adoption, shelter, volunteer, puppies, kittens, neuter, rescue, animals, nurture, injure, donate, pets, abandon, haven, aileen, setia, alam, strays, companion, declaw",
      },
    ],
  },
  async created() {
    await this.loadArticle();
    window.addEventListener("scroll", async () => {
      if (
        window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight - 2
      ) {
        await this.loadMore();
      }
    });
  },
  methods: {
    tagPress(tag) {
      // console.log(tag);
      if (tag == "all") this.filteredUpdates = [];
      else {
        this.filteredUpdates = this.furryUpdates.filter(
          (update) => update.tag == tag
        );
      }
    },
    async loadArticle() {
      let blogRef = await db
        .collection("blogs")
        .orderBy("realDate", "desc")
        .limit(3);
      var test = [];
      await blogRef.get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          var put = doc.data();
          put.id = doc.id;
          // doc.data() is never undefined for query doc snapshots
          test.push(put);
        });
      });
      var lastDoc;
      await blogRef.get().then(function (documentSnapshots) {
        lastDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];
      });
      let dbRef2 = db.collection("etc").doc("tags");
      await dbRef2
        .get()
        .then((doc) => {
          this.tags = doc.data().tags;
        })
        .catch((error) => {
          console.error(error);
        });

      this.lastDocument = lastDoc;
      this.furryUpdates = test;
    },

    async loadMore() {
      document.getElementById("loading-screen").style.display = "flex";
      if (!this.finalPage && !this.loading) {
        this.loading = true;
        let blogRef = await db
          .collection("blogs")
          .orderBy("realDate", "desc")
          .startAfter(this.lastDocument)
          .limit(3);

        var test1 = this.furryUpdates;
        await blogRef.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            var put = doc.data();
            put.id = doc.id;
            if (!test1.includes(put)) test1.push(put);
          });
        });

        var lastDoc;
        await blogRef.get().then((documentSnapshots) => {
          lastDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];
          this.loading = false;
          // console.log(lastDoc);
        });

        if (lastDoc === undefined) {
          this.finalPage = true;
        } else {
          this.lastDocument = lastDoc;
          this.furryUpdates = test1;
        }
      }
      document.getElementById("loading-screen").style.display = "none";
    },
    goToArticle(title) {
      this.articleID = "/" + title;
      const location = window.location.href + this.articleID;
      window.location.assign(location);
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/variables.sass'

.our-work
  background: white
  height: 100%
  .banner-section
    width: 100%
    img
      width: 100%
  .work-container
    max-width: 1100px
    padding: 20px 20px 0 20px
    margin: 0 auto
    .header-title
      h1
        font-family: RobotoSlab
        color: $primary-orange
        font-weight: bold
        font-size: 36px
        line-height: 40px
        margin-bottom: 30px
        +mobile
          font-size: 24px
          line-height: 28px
    .content-wrapper
      width: 100%
      display: flex
      flex-direction: row
      margin: 0 auto
      justify-content: center
      +mobile
        flex-direction: column
      .content-left
        width: 70%
        text-align: left
        +mobile
          width: 100%
        .news-box
          background-color: #fff
          border: 3px solid $primary-lighto
          padding: 20px
          display: flex
          flex-direction: column
          margin-bottom: 30px
          .news-title
            text-align: center
            h1
              font-size: 32px
              line-height: 36px
              color: $primary-orange
              font-family: 'RobotoSlab'
              font-weight: bold
              margin-bottom: 0
              +mobile
                font-size: 24px
                line-height: 28px
          .date-tags-container
            display: flex
            align-items: center
            margin-bottom: 15px
            justify-content: center
            .date
              p
                font-size: 16px
                line-height: 22px
                font-weight: bold
                color: $primary-grey
                margin: 0
                +mobile
                  font-size: 14px
                  line-height: 18px
            .tags
              display: flex
              align-items: center
              margin-left: 20px
              img
                width: 23px
                height: 26px
                margin-bottom: 5px
                margin-right: 5px
              p
                font-size: 16px
                line-height: 22px
                font-weight: bold
                color: $primary-orange
                margin: 0
                +mobile
                  font-size: 14px
                  line-height: 18px
          .news-info
            display: flex
            height: 250px
            +mobile
              flex-direction: column
              height: auto
            .news-image
              margin-right: 20px
              +mobile
                margin: 0 auto
              img
                width: 250px
                height: 250px
            .news-content
              display: flex
              flex-direction: column
              text-align: left
              position: relative
              height: 100%
              .news-text
                max-height: 200px
                overflow: hidden
                color: $primary-grey !important
                +mobile
                  max-height: 150px
                ::v-deep span
                  color: $primary-grey !important
                  font-size: 16px
                  line-height: 22px
                  +mobile
                    font-size: 14px
                    line-height: 18px
                ::v-deep p
                  color: $primary-grey !important
                  font-size: 16px
                  line-height: 22px
                  +mobile
                    font-size: 14px
                    line-height: 18px
                ::v-deep strong
                  color: $primary-grey !important
                  font-size: 16px
                  line-height: 22px
                  +mobile
                    font-size: 14px
                    line-height: 18px

              .read-button
                display: flex
                align-items: center
                padding: 5px 10px
                border: 1px solid $primary-lighto
                width: 130px
                background: $primary-lighto
                position: absolute
                bottom: 0
                &::after
                  content: ''
                  background-image: url('../assets/images/general/arrow-line-o.svg')
                  width: 20px
                  height: 15px
                  position: absolute
                  right: 8px
                  top: 50%
                  transform: translateY(-60%)
                &:hover, &:active, &:focus
                  border: 1px solid #fff
                  background-color: $primary-orange
                  &::after
                    content: ''
                    background-image: url('../assets/images/general/arrow-line-w.svg')
                  p
                    color: #fff
                  svg
                    stroke: #fff
                +mobile
                  position: relative
                  margin-top: 20px
                p
                  color: $primary-orange
                  font-weight: bold
                  margin-bottom: 0
                img
                  margin-left: 5px

      .content-right
        width: 20%
        margin-left: 30px
        height: auto
        +mobile
          width: 100%
          margin: 0 auto 30px
        .content-tags
          background-color: #fff
          border: 3px solid $primary-lighto
          .top-box
            padding: 20px
            border-bottom: 3px solid $primary-lighto
            h3
              font-size: 24px
              line-height: 28px
              color: #7c7c7c
          .bottom-box
            button
              width: 100%
              padding: 10px 20px
              color: $primary-orange
              border-bottom: 3px solid $primary-lighto
              border: 3px solid transparent
              &:nth-last-child(1)
                border-bottom: 0
              &:hover
                color: #fff
                background-color: $primary-orange
              &:focus
                color: #fff
                background-color: $primary-orange
                outline: $primary-orange
                border: 3px solid $primary-orange
              &.selected
                color: #fff
                background-color: $primary-orange
                outline: $primary-orange
                border: 3px solid $primary-orange

hr
  color: $primary-grey2
  width: 70%
  margin: 30px auto
  opacity: 1

.last-container
  text-align: center
  margin-bottom: 50px
  h2
    font-size: 18px
    line-height: 20px
    color: $primary-orange
    font-family: 'RobotoSlab'
    font-weight: bold
</style>
