<template>
  <v-container>
    <v-row class="justify-center" no-gutters>
      <v-card class="justify-center">
        <v-card-text>
          <h3 class="display-3 ext-center">Update Animal</h3>
          <v-form>
            <v-text-field
              v-model="animal.name"
              label="Name"
              required
            ></v-text-field>
            <v-text-field
              v-model="animal.age"
              label="Age (Type the years/month as well)"
              required
            ></v-text-field>
            <v-select
              outlined
              :items="type"
              label="Animal"
              v-model="animal.type"
            ></v-select>
            <v-select
              outlined
              :items="gender"
              label="Gender"
              v-model="animal.gender"
            ></v-select>
            <v-textarea
              v-model="animal.description"
              label="Description"
              required
            ></v-textarea>

            <v-container>
              <v-row justify="start">
                <v-col justify="start">
                  <label class="text-h5 lab text--primary labels text-center"
                    >Add Thumbnail Photo (Only 1 Picture)</label
                  >
                  <input
                    type="file"
                    ref="input1"
                    @change="previewImage3"
                    accept="image/*"
                    multiple
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-container class="form-group" v-if="thumbnail.length !== 0">
              <v-row justify="center">
                <v-col cols="12">
                  <v-card>
                    <v-img :src="thumbnail[0].url" class="preview"></v-img>
                    <v-btn @click.prevent="onDelete3(thumbnail[0].reference)"
                      >Delete</v-btn
                    >
                  </v-card>
                </v-col>
              </v-row>
            </v-container>

            <v-container>
              <v-row>
                <v-col>
                  <label class="display-1 text--primary">Add More Photos</label>
                  <input
                    type="file"
                    ref="input1"
                    @change="previewImage"
                    accept="image/*"
                    multiple
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-container class="form-group" v-if="imageList.length > 0">
              <v-row justify="center">
                <v-col
                  cols="3"
                  v-for="image in imageList"
                  :key="image.reference"
                >
                  <v-card>
                    <v-img class="preview" :src="image.url"></v-img>
                    <div class="utilTab">
                      <select class="selectIndex" v-model="imageIndexMap[image.reference]">
                        <option class="options" v-for="number in arrayList" :key="number" :value="number">{{number}}</option>
                      </select>
                      <v-btn @click.prevent="onDelete(image.reference)"
                        >Delete</v-btn
                      >

                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions class="justify-center">
              <v-btn
                :disabled="allowButton"
                @click="onUpdateForm"
                type="submit"
                class="btn btn-primary btn-block"
                >Finish Editing</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "../main";
import firebase from "firebase";

export default {
  data() {
    return {
      type: ["Cat", "Dog"],
      gender: ["Male", "Female"],
      animal: {},
      imageList: [],
      originalList: [],
      deleteRefAfter: [],
      arrayList:[],
      imageIndexMap: {},
      thumbnail: [],
      imageData: null,
    };
  },
  computed: {
    allowButton() {
      return !(
        this.animal.name.length > 0 &&
        this.animal.type.length > 0 &&
        this.animal.gender.length > 0 &&
        this.animal.age.length > 0
      );
    },
  },
  created() {
    let dbRef = db.collection("animals").doc(this.$route.params.id);
    dbRef
      .get()
      .then((doc) => {
        this.animal = doc.data();
        this.imageList = doc.data().imageList;
        this.originalList = doc.data().imageList;
        if (this.animal.thumbnail.length !== 0) {
          this.thumbnail.push(this.animal.thumbnail);
        }
        this.imageList.forEach((image, index) =>{
          this.imageIndexMap[image.reference] = index;
          this.arrayList.push(index);
        });
        console.log(this.imageIndexMap);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    onUpdateForm(event) {
      event.preventDefault();
      this.animal.imageList = this.imageList;
      this.animal.thumbnail = this.thumbnail[0];
      console.log(this.imageIndexMap);
      var indexMap = {}
      console.log(this.deleteRefAfter);
      var errorFound = false;
      Object.keys(this.imageIndexMap).forEach((x=>{
        if(!(this.deleteRefAfter.includes(x))){

          if(indexMap[this.imageIndexMap[x]] == undefined || indexMap[this.imageIndexMap[x]] == null){
            indexMap[this.imageIndexMap[x]] = x;
          }
          else{
            errorFound= true;
            window.alert("Duplicate indexes found. Please fix the issue before proceeding");
            return;
          }
        }
      }))
      if(errorFound){
        return;
      }
      var properImageList = [];
      //We need to match the image array list
      for(var i = 0; i<this.imageList.length; i++){
        if(indexMap[i] == undefined || indexMap[i] == null){
          window.alert("There seems to be a problem with the indexing sequence.\nPlease fix the indexing");
          return;
        }
        var imageRef = indexMap[i];
        properImageList.push(this.imageList.filter(image => image.reference == imageRef)[0])
      }
      this.animal.imageList = properImageList;
      for (this.ref of this.deleteRefAfter) {
        const storageRef = firebase.storage().ref();
        var deletedRef = storageRef.child(this.ref);
        deletedRef
          .delete()
          .then(function () {
            console.log("DELETED PHOTO");
            // File deleted successfully
          })
          .catch(function (error) {
            // Uh-oh, an error occurred!
            console.error(error);
          });
      }
      db.collection("animals")
        .doc(this.$route.params.id)
        .update(this.animal)
        .then(() => {
          alert("Animal successfully updated!");
          this.$router.push("/admin/animalList");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    previewImage(event) {
      this.uploadValue = 0;
      // console.log(event.target.files.length);
      this.imageData = event.target.files;
      this.onUpload();
    },

    onUpload() {
      for (this.data of this.imageData) {
        const name = Date.now() + this.data.name;
        const storageRef = firebase.storage().ref(name).put(this.data);
        storageRef.on(
          `state_changed`,
          (snapshot) => {
            this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.error(error.message);
          },
          () => {
            this.uploadValue = 100;
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              this.imageList.push({ reference: name, url: url });
            });
          }
        );
      }
    },
    onDelete(reference) {
      //Just deletes it from the list but not in db
      var deleteOnFB = true;
      for (let i = this.imageList.length - 1; i >= 0; i--) {
        if (this.imageList[i].reference === reference) {
          this.imageList.splice(i, 1);
        }
      }
      for (let i = 0; i < this.originalList.length; i++) {
        if (this.originalList[i].reference === reference) {
          deleteOnFB = false;
          this.deleteRefAfter.push(reference);
        }
      }
      if (deleteOnFB) {
        const storageRef = firebase.storage().ref();
        var deletedRef = storageRef.child(reference);
        deletedRef
          .delete()
          .then(function () {
            console.log("DELETED " + reference + " !");
            // File deleted successfully
          })
          .catch(function (error) {
            // Uh-oh, an error occurred!
            console.error(error);
          });
      }
    },

    previewImage3(event) {
      this.uploadValue = 0;
      // console.log(event.target.files.length);
      if (event.target.files.length > 1) {
        alert.log("Too many files");
      } else {
        this.imageData3 = event.target.files;
        this.onUpload3();
      }
    },
    onUpload3() {
      for (this.data of this.imageData3) {
        const name = Date.now() + this.data.name;
        const storageRef = firebase.storage().ref(name).put(this.data);
        storageRef.on(
          `state_changed`,
          (snapshot) => {
            this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.error(error.message);
          },
          () => {
            this.uploadValue = 100;
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              if (this.thumbnail.length !== 0) {
                const StorageRef = firebase.storage().ref();
                var deletedRef = StorageRef.child(this.thumbnail[0].reference);
                deletedRef
                  .delete()
                  .then(function () {
                    console.log(
                      "DELETED " + this.mainImage[0].reference + " !"
                    );
                    // File deleted successfully
                  })
                  .catch(function (error) {
                    // Uh-oh, an error occurred!
                    console.error(error);
                  });
              }
              this.thumbnail = [];
              this.thumbnail.push({ reference: name, url: url });
            });
          }
        );
      }
    },
    onDelete3(reference) {
      this.thumbnail = [];
      const storageRef = firebase.storage().ref();
      var deletedRef = storageRef.child(reference);
      deletedRef
        .delete()
        .then(function () {
          console.log("DELETED " + reference + " !");
          // File deleted successfully
        })
        .catch(function (error) {
          // Uh-oh, an error occurred!
          console.error(error);
        });
    },
  },
};
</script>
<style>
.btn-primary {
  margin-right: 12px;
}
label {
  display: block;
}
.utilTab{
  display:flex;
  flex-direction:row;
}
.selectIndex{
  flex: 1;
  display:flex;
  width: 20%;
  background: white;
  text-align: center;
  justify-content: center !important;
  align-items: center;
  text-align-last: center;

}

</style>