<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-col cols="6">
        <v-card height="100%" elevation="2">
          <v-alert
            color="red white--text"
            class="justify-center"
            v-if="unsuccessful"
          >
            Incorrect E-mail/Password
          </v-alert>
          <v-card-title class="justify-center">Login</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="pressed">
              <v-text-field
                label="Email"
                v-model="email"
                :rules="emailRules"
                required
              >
              </v-text-field>
              <v-text-field
                :type="'password'"
                v-model="password"
                label="Password"
              ></v-text-field>
              <v-btn block type="submit"> Login </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center"> </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
export default {
  data() {
    return {
      unsuccessful: false,
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      password: "",
      error: "",
    };
  },
  methods: {
    async pressed() {
      try {
        const val = await firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password);
        // console.log(val);
        this.$router.replace({ name: "Dashboard" });
      } catch (err) {
        this.unsuccessful = true;
        console.error(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  color: red;
  font-size: 18px;
}
input {
  width: 400px;
  padding: 30px;
  margin: 20px;
  font-size: 21px;
}
.v-btn {
  min-width: 0;
}
</style>