<template>
  <v-container fluid>
    <v-row justify="start" align="start">
      <v-col>
        <v-simple-table class="table table-striped text-center" width="100vw">
          <thead>
            <tr>
              <th class="title text--primary text-center">Date</th>
              <th class="title text--primary text-center">Pet ID</th>
              <th class="title text--primary text-center">Name</th>
              <th class="title text--primary text-center">E-mail</th>
              <th class="title text--primary text-center">Phone Number</th>
              <th class="title text--primary text-center">Questions</th>
              <th class="title text--primary text-center">Settings</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="enquiry in enquiries" :key="enquiry.key">
              <td>{{ enquiry.date }}</td>
              <td>{{ enquiry.petId }}</td>
              <td>{{ enquiry.name }}</td>
              <td>{{ enquiry.email }}</td>
              <td>{{ enquiry.phoneNumber }}</td>
              <td v-if="enquiry.reason.length > 0">Please view in Edit</td>
              <td v-else>None</td>
              <td>
                <v-btn
                  color="error"
                  type="submit"
                  @click.prevent="deleteEnq(enquiry.key)"
                  class="btn btn-danger"
                  >Delete</v-btn
                >
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "../main";
import moment from "moment";

export default {
  data() {
    return {
      enquiries: [],
    };
  },
  created() {
    db.collection("adoptionEnquiries").onSnapshot((snapshotChange) => {
      this.enquiries = [];
      snapshotChange.forEach((doc) => {
        this.enquiries.push({
          key: doc.id,
          petId: doc.data().petId,
          name: doc.data().name,
          phoneNumber: doc.data().phoneNumber,
          email: doc.data().email,
          reason: doc.data().reason,
          date: moment(doc.data().date).format("l"),
          editURL: "/admin/enquiryEdit/".concat(doc.id),
        });
      });
    });
  },
  methods: {
    deleteEnq(id) {
      if (window.confirm("Do you really want to delete?")) {
        db.collection("adoptionEnquiries")
          .doc(id)
          .delete()
          .then(() => {
            console.log("enquiry deleted!");
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
};
</script>

<style>
.btn-primary {
  margin-right: 12px;
}
.concat {
  white-space: nowrap;
  overflow: hidden;
  width: 50px;
  height: 30px;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
}
</style>