<template>
  <v-container fill-height>
    <v-row class="justify-center" no-gutters>
      <v-card class="card justify-center">
        <v-card-text>
          <h3 class="display-2 text--primary">Create an Update</h3>
          <v-form ref="form">
            <v-row justify="center">
              <v-col cols="8">
                <label class="lab text-h4 text--primary text-start"
                  >Title</label
                >
                <v-text-field
                  outlined
                  v-model="update.title"
                  label="Title"
                  required
                ></v-text-field>

                <label class="lab text-h4 text--primary text-start"
                  >Content</label
                >
                <vue-editor
                  id="editor"
                  :editor-toolbar="customToolbar"
                  v-model="update.content"
                >
                </vue-editor>
              </v-col>
            </v-row>
            <v-card-actions class="justify-center">
              <v-btn
                :disabled="allowButton"
                @click="onFormSubmit"
                type="submit"
                class="btn btn-primary btn-block"
                >Create Update</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import { db } from "../main";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      update: {
        title: "",
        content: "",
        date: "",
        realDate: "",
      },
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
    };
  },
  computed: {
    allowButton() {
      return !(this.update.title.length > 0 && this.update.content.length > 0);
    },
  },
  methods: {
    onFormSubmit(event) {
      this.update.date = moment
        .tz(Date.now(), "Asia/Kuala_Lumpur")
        .format("D MMMM YYYY");
      this.update.realDate = Date.now();
      event.preventDefault();
      db.collection("updates")
        .add(this.update)
        .then(() => {
          alert("Update successfully created!");
          this.update.title = "";
          this.update.content = "";
          this.$router.push("/admin/updateList");
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/variables.sass'
.card
  +desktop
    width: 60%
  +mobile
    width: 100%
</style>
