<template>
  <v-container fill-height>
    <v-row class="justify-center" no-gutters>
      <v-card class="card justify-center">
        <v-card-text>
          <h3 class="display-1 text--primary">Edit Banners for Home Page</h3>
          <v-form @submit.prevent="onFormSubmit" ref="form">
            <v-row justify="center">
              <v-col cols="12">
                <v-container>
                  <v-row justify="start">
                    <v-col justify="start">
                      <label
                        class="text-h5 lab text--primary labels text-center"
                        >Add Banner Photos (Only 6 Pictures)</label
                      >
                      <input
                        type="file"
                        ref="input1"
                        @change="previewImage"
                        accept="image/*"
                        multiple
                      />
                    </v-col>
                  </v-row>
                </v-container>
                <v-container class="form-group" v-if="bannerURLS.length !== 0">
                  <v-row justify="center">
                    <v-col cols="12">
                      <v-card
                        class="img"
                        v-for="banner of bannerURLS"
                        :key="banner.reference"
                      >
                        <v-img :src="banner.URL" class="preview"></v-img>
                        <v-btn @click.prevent="onDelete(banner.reference)"
                          >Delete</v-btn
                        >
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <v-card-actions class="justify-center">
              <v-btn type="submit" class="btn btn-primary btn-block"
                >Finish Editing</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "../main";
import firebase from "firebase";
export default {
  data() {
    return {
      imageData: null,
      originalURLS: [],
      bannerURLS: [],
      deleteRefAfter: [],
      banners: {
        URLS: [],
      },
    };
  },
  created() {
    let dbRef = db.collection("homeBanners").doc("banners");
    dbRef
      .get()
      .then((doc) => {
        this.originalURLS = doc.data().URLS;
        this.bannerURLS = doc.data().URLS;
        // console.log(this.bannerURLS);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    onFormSubmit() {
      if (this.bannerURLS.length !== 0) this.banners.URLS = this.bannerURLS;
      else this.banner.URLS = 0;

      for (this.ref of this.deleteRefAfter) {
        const storageRef = firebase.storage().ref();
        var deletedRef = storageRef.child(this.ref);
        deletedRef
          .delete()
          .then(function () {
            console.log("DELETED PHOTO");
            // File deleted successfully
          })
          .catch(function (error) {
            // Uh-oh, an error occurred!
            console.error(error);
          });
      }

      db.collection("homeBanners")
        .doc("banners")
        .update(this.banners)
        .then(() => {
          console.log("Home banner successfully updated!");
          this.$router.push("/admin/dashboard");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    previewImage(event) {
      this.uploadValue = 0;
      // console.log(event.target.files.length);
      if (event.target.files.length > 6 || this.bannerURLS.length >= 6) {
        window.alert("Too many files uploaded. Please delete some.");
      } else {
        this.imageData = event.target.files;
        this.onUpload();
      }
    },
    onUpload() {
      for (this.data of this.imageData) {
        const name = Date.now() + this.data.name;
        const storageRef = firebase.storage().ref(name).put(this.data);
        storageRef.on(
          `state_changed`,
          (snapshot) => {
            this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.error(error.message);
          },
          () => {
            this.uploadValue = 100;
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              this.bannerURLS.push({ reference: name, URL: url });
            });
          }
        );
      }
    },
    onDelete(reference) {
      var deleteOnFB = true;
      for (let i = this.bannerURLS.length - 1; i >= 0; i--) {
        if (this.bannerURLS[i].reference === reference) {
          this.bannerURLS.splice(i, 1);
        }
      }
      for (let i = 0; i < this.originalURLS.length; i++) {
        if (this.originalURLS[i].reference === reference) {
          deleteOnFB = false;
          this.deleteRefAfter.push(reference);
        }
      }
      if (deleteOnFB) {
        // console.log("hi");
        const storageRef = firebase.storage().ref();
        var deletedRef = storageRef.child(reference);
        deletedRef
          .delete()
          .then(function () {
            console.log("DELETED " + reference + " !");
            // File deleted successfully
          })
          .catch(function (error) {
            // Uh-oh, an error occurred!
            console.error(error);
          });
      }
      // console.log("deleteRefAfter");
    },
  },
};
</script>
<style lang="sass" scoped>
@import '../sass/variables.sass'
.card
  +desktop
    width: 60%
  +mobile
    width: 100%
.labels
  margin-bottom: 1%
.lab
  margin-bottom: 5%

.preview
  height: 175px

.testtt
  margin-top: 10%

.img
  margin-bottom: 5%
</style>