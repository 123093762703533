<template>
  <v-container fluid>
    <v-row justify="start" align="start">
      <v-col>
        <v-simple-table class="table table-striped text-center" width="100vw">
          <thead>
            <tr>
              <th class="title text--primary text-center">Title</th>
              <th class="title text--primary text-center">Date</th>
              <th class="title text--primary text-center">Content</th>
              <th class="title text--primary text-center">Thumbnail</th>
              <th class="title text--primary text-center">Main Image</th>
              <th class="title text--primary text-center">Number of Photos</th>
              <th class="title text--primary text-center">Settings</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="blog in blogs" :key="blog.key">
              <td>{{ blog.title }}</td>
              <td>{{ blog.date }}</td>
              <td v-if="blog.content.length > 0">Please view in Edit</td>
              <td v-else>None</td>
              <td v-if="blog.thumbnail.url !== undefined">Present</td>
              <td v-else>None</td>
              <td v-if="blog.mainImage.url !== undefined">Present</td>
              <td v-else>None</td>
              <td>{{ blog.images.length }}</td>
              <td>
                <v-btn :to="blog.editURL" class="btn-primary">Edit</v-btn>
                <v-btn
                  color="error"
                  type="submit"
                  @click.prevent="deleteblog(blog.key)"
                  class="btn btn-danger"
                  >Delete</v-btn
                >
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "../main";
import firebase from "firebase";

export default {
  data() {
    return {
      blogs: [],
      deletingImgs: [],
      deletingImgs2: [],
      deletingImgs3: [],
    };
  },
  async created() {
    await db
      .collection("blogs")
      .orderBy("realDate", "desc")
      .onSnapshot((snapshotChange) => {
        this.blogs = [];
        snapshotChange.forEach((doc) => {
          this.blogs.push({
            key: doc.id,
            title: doc.data().title,
            content: doc.data().content,
            date: doc.data().date,
            images: doc.data().gallery,
            mainImage: doc.data().mainImage,
            thumbnail: doc.data().thumbnail,
            editURL: "/admin/blogEdit/".concat(doc.id),
          });
        });
      });
  },
  methods: {
    deleteblog(id) {
      if (window.confirm("Do you really want to delete?")) {
        for (this.blog of this.blogs) {
          if (id === this.blog.key) {
            this.deletingImgs = this.blog.images;
            this.deletingImgs2 = this.blog.mainImage;
            this.deletingImgs3 = this.blog.thumbnail;
            break;
          }
        }
        for (this.img of this.deletingImgs) {
          const storageRef = firebase.storage().ref();
          var deletedRef = storageRef.child(this.img.reference);
          deletedRef
            .delete()
            .then(function () {
              console.log("Deleted Photo!");
              // File deleted successfully
            })
            .catch(function (error) {
              // Uh-oh, an error occurred!
              console.error(error);
            });
          console.log("Deleted 0");
        }

        if (this.deletingImgs2.length !== 0) {
          const storageRef2 = firebase.storage().ref();
          var deletedRef2 = storageRef2.child(this.deletingImgs2.reference);
          deletedRef2
            .delete()
            .then(function () {
              console.log("Deleted Photo!");
              // File deleted successfully
            })
            .catch(function (error) {
              // Uh-oh, an error occurred!
              console.error(error);
            });
          console.log("Deleted 1");
        }

        if (this.deletingImgs3.length !== 0) {
          const storageRef3 = firebase.storage().ref();
          var deletedRef3 = storageRef3.child(this.deletingImgs3.reference);
          deletedRef3
            .delete()
            .then(function () {
              console.log("Deleted Photo!");
              // File deleted successfully
            })
            .catch(function (error) {
              // Uh-oh, an error occurred!
              console.error(error);
            });
          console.log("Deleted 2");
        }
        db.collection("blogs")
          .doc(id)
          .delete()
          .then(() => {
            console.log("blog deleted!");
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
};
</script>

<style>
.btn-primary {
  margin-right: 12px;
}
.concat {
  white-space: nowrap;
  overflow: hidden;
  width: 50px;
  height: 30px;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
}
</style>