<template lang="pug">
v-app#app(fluid)
  top-header
  #loading-screen
    .spinning-loader
  v-main.body
    <router-view />
  footerVue
</template>
<script>
import TopHeaderVue from "./components/Top-Header.vue";
import footerVue from "./components/footer.vue";

export default {
  metaInfo: {
    title: "My Pets Haven",
    titleTemplate: "My Pets Haven | %s",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  components: {
    "top-header": TopHeaderVue,
    footerVue: footerVue,
  },
};
</script>

<style lang="sass" scoped>
#app
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center

.page-container
  max-width: 1280px

#loading-screen
  position: fixed
  z-index: 9999999999
  width: 100%
  height: 100%
  display: none
  justify-content: center
  align-items: center
  background-color: rgba(201,201,201, 0.5)
  .spinning-loader
    width: 50px
    height: 50px
    border: 5px solid rgba(255,151,133, 0.5)
    border-left-color: rgb(255,151,133)
    border-radius: 50%
    background: transparent
    animation-name: rotate-s-loader
    animation-iteration-count: infinite
    animation-duration: 1s
    animation-timing-function: linear
    position: relative

@keyframes rotate-s-loader
  from
    transform: rotate(0)
  to
    transform: rotate(360deg)

.body
  // background: rgba(235, 140, 166, 0.3)
  min-height: 100vh

.navbar
  background: rgba(235, 140, 166, 0.3)

#nav a
  font-weight: bold
  color: #5e0f50

#nav a.router-link-exact-active
  color: #42b983

#overlay
  display: none
  position: absolute
  z-index: 1000000
  width: 100%
  height: 100%
  background-color: #000

h1
  margin-bottom: 20px
</style>
