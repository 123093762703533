<template>
  <v-container fluid>
    <v-row justify="start" align="start">
      <v-col>
        <v-simple-table class="table table-striped text-center" width="100vw">
          <thead>
            <tr>
              <th class="title text--primary">Name</th>
              <th class="title text--primary">Animal</th>
              <th class="title text--primary">Gender</th>
              <th class="title text--primary">Age (years)</th>
              <th class="title text--primary">Description</th>
              <th class="title text--primary">Thumbnail</th>
              <th class="title text--primary">Number of Pictures</th>
              <th class="title text--primary">Settings</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="animal in Animals" :key="animal.key">
              <td>{{ animal.name }}</td>
              <td>{{ animal.type }}</td>
              <td>{{ animal.gender }}</td>
              <td>{{ animal.age }}</td>
              <td v-if="animal.description.length > 8">
                {{ animal.description.substring(0, 8).concat("...") }}
              </td>
              <td v-else>{{ animal.description }}</td>
              <td v-if="animal.thumbnail.url !== undefined">Present</td>
              <td v-else>None</td>
              <td>{{ animal.imageList.length }}</td>
              <td>
                <v-btn :to="animal.editURL" class="btn-primary">Edit</v-btn>
                <v-btn
                  color="error"
                  type="submit"
                  @click.prevent="deleteAnimal(animal.key)"
                  class="btn btn-danger"
                  >Delete</v-btn
                >
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "../main";
import firebase from "firebase";

export default {
  data() {
    return {
      Animals: [],
      deletingImgs: [],
      deletingImgs2: [],
    };
  },
  created() {
    db.collection("animals").onSnapshot((snapshotChange) => {
      this.Animals = [];
      snapshotChange.forEach((doc) => {
        this.Animals.push({
          key: doc.id,
          name: doc.data().name,
          type: doc.data().type,
          gender: doc.data().gender,
          age: doc.data().age,
          thumbnail: doc.data().thumbnail,
          description: doc.data().description,
          imageList: doc.data().imageList,
          editURL: "/admin/editAnimal/".concat(doc.id),
        });
      });
    });
  },
  methods: {
    deleteAnimal(id) {
      if (window.confirm("Do you really want to delete?")) {
        for (this.animal of this.Animals) {
          if (id === this.animal.key) {
            this.deletingImgs = this.animal.imageList;
            this.deletingImgs3 = this.animal.thumbnail;
            break;
          }
        }
        for (this.img of this.deletingImgs) {
          const storageRef = firebase.storage().ref();
          var deletedRef = storageRef.child(this.img.reference);
          deletedRef
            .delete()
            .then(function () {
              console.log("Deleted Photo!");
              // File deleted successfully
            })
            .catch(function (error) {
              // Uh-oh, an error occurred!
              console.error(error);
            });
        }

        if (this.deletingImgs3.length !== 0) {
          const storageRef3 = firebase.storage().ref();
          var deletedRef3 = storageRef3.child(this.deletingImgs3.reference);
          deletedRef3
            .delete()
            .then(function () {
              console.log("Deleted Thumbnail!");
              // File deleted successfully
            })
            .catch(function (error) {
              // Uh-oh, an error occurred!
              console.error(error);
            });
          console.log("Deleted 2");
        }
        db.collection("animals")
          .doc(id)
          .delete()
          .then(() => {
            console.log("Animal deleted!");
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
};
</script>

<style>
.btn-primary {
  margin-right: 12px;
}
.concat {
  white-space: nowrap;
  overflow: hidden;
  width: 50px;
  height: 30px;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
}
</style>