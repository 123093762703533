<template>
    <div>
        <v-img class="img" :src="image_src"></v-img>
    </div>
</template>

<script>
    export default {
      data() {
        return {
        image_src: require("../assets/error404.svg"),
        };
      }
        
    }
</script>

<style lang="scss" scoped>
.img{
    height:100%;
    width: 100%;
}

</style>