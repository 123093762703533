<template lang="pug">
.nav
  .user-nav(v-if="!admin")
    .nav-container
      a.left-div(href="/")
        .logo-container
          img(src="../assets/images/logo.png")
        h1.shop-title My Pets' Haven
      .right-div
        img.mobile(
          @click="showNav",
          src="../assets/images/general/sandwich-o.svg"
        )
        .tabs-container.desktop
          a#tab1(href="/aboutus")
            .tab
              p ABOUT US
              svg.base(
                width="16",
                height="26",
                viewBox="0 0 16 26",
                fill="none",
                xmlns="http://www.w3.org/2000/svg"
              )
                path(d="M2 2L13 13L2 24", stroke-width="3")
          a#tab2(href="/ourwork")
            .tab
              p OUR WORK
              svg.base(
                width="16",
                height="26",
                viewBox="0 0 16 26",
                fill="none",
                xmlns="http://www.w3.org/2000/svg"
              )
                path(d="M2 2L13 13L2 24", stroke-width="3")
          #tab3
            .tab
              p ADOPT
              svg.base(
                width="16",
                height="26",
                viewBox="0 0 16 26",
                fill="none",
                xmlns="http://www.w3.org/2000/svg"
              )
                path(d="M2 2L13 13L2 24", stroke-width="3")
            .dropdown-container.desktop
              a#tab31.inner-tab(href="/adopt")
                p Animals for Adoption
                svg(
                  width="16",
                  height="26",
                  viewBox="0 0 16 26",
                  fill="none",
                  xmlns="http://www.w3.org/2000/svg"
                )
                  path(d="M2 2L13 13L2 24", stroke-width="3")
              a#tab32.inner-tab(href="/adoptionPolicy")
                p Adoption <br>Policy
                svg(
                  width="16",
                  height="26",
                  viewBox="0 0 16 26",
                  fill="none",
                  xmlns="http://www.w3.org/2000/svg"
                )
                  path(d="M2 2L13 13L2 24", strokeWidth="3")
          a#tab4(href="/donate")
            .tab
              p DONATE
              svg.base(
                width="16",
                height="26",
                viewBox="0 0 16 26",
                fill="none",
                xmlns="http://www.w3.org/2000/svg"
              )
                path(d="M2 2L13 13L2 24", strokeWidth="3")
          a#tab5(href="/volunteer")
            .tab
              p VOLUNTEER
              svg.base(
                width="16",
                height="26",
                viewBox="0 0 16 26",
                fill="none",
                xmlns="http://www.w3.org/2000/svg"
              )
                path(d="M2 2L13 13L2 24", strokeWidth="3")
          a#tab6(href="/contactus")
            .tab
              p CONTACT US
              svg.base(
                width="16",
                height="26",
                viewBox="0 0 16 26",
                fill="none",
                xmlns="http://www.w3.org/2000/svg"
              )
                path(d="M2 2L13 13L2 24", strokeWidth="3")
    .mobileNav.mobile(
      :class="{ active: navActive === true, disappear: navActive === false }"
    )
      a#tab1.tab(href="/aboutus")
        p ABOUT US
        svg(
          width="16",
          height="26",
          viewBox="0 0 16 26",
          fill="none",
          xmlns="http://www.w3.org/2000/svg"
        )
          path(d="M2 2L13 13L2 24", stroke-width="3")

      a#tab2.tab(href="/ourwork")
        p OUR WORK
        svg(
          width="16",
          height="26",
          viewBox="0 0 16 26",
          fill="none",
          xmlns="http://www.w3.org/2000/svg"
        )
          path(d="M2 2L13 13L2 24", stroke-width="3")
      #mtab3
        #mtab.tab(@click="showAdditional()")
          p ADOPT
          svg.arrow(
            width="16",
            height="26",
            viewBox="0 0 16 26",
            fill="none",
            xmlns="http://www.w3.org/2000/svg"
          )
            path(d="M2 2L13 13L2 24", stroke-width="3")
        #drop.mobile-dropdown(data-set="neutral")
          a#tab31.inner-tab(href="/adopt")
            p Animals for Adoption
            svg(
              width="16",
              height="26",
              viewBox="0 0 16 26",
              fill="none",
              xmlns="http://www.w3.org/2000/svg"
            )
              path(d="M2 2L13 13L2 24", stroke-width="3")
          a#tab32.inner-tab(href="/adoptionPolicy")
            p Adoption Policy
            svg(
              width="16",
              height="26",
              viewBox="0 0 16 26",
              fill="none",
              xmlns="http://www.w3.org/2000/svg"
            )
              path(d="M2 2L13 13L2 24", stroke-width="3")
      a#tab4.tab(href="/donate")
        p DONATE
        svg(
          width="16",
          height="26",
          viewBox="0 0 16 26",
          fill="none",
          xmlns="http://www.w3.org/2000/svg"
        )
          path(d="M2 2L13 13L2 24", stroke-width="3")
      a#tab5.tab(href="/volunteer")
        p VOLUNTEER
        svg(
          width="16",
          height="26",
          viewBox="0 0 16 26",
          fill="none",
          xmlns="http://www.w3.org/2000/svg"
        )
          path(d="M2 2L13 13L2 24", stroke-width="3")
      a#tab6.tab.last(href="/contactus")
        p CONTACT US
        svg(
          width="16",
          height="26",
          viewBox="0 0 16 26",
          fill="none",
          xmlns="http://www.w3.org/2000/svg"
        )
          path(d="M2 2L13 13L2 24", stroke-width="3")

  .admin-nav(v-else="admin")
    v-app-bar.navbar( app, dark)
      v-btn(to="/") Home
      v-spacer
      v-toolbar-items(color="blue")
        v-btn(@click="signOut", v-if="loggedIn") Sign Out
        v-btn(to="/login", v-else) Login
        v-btn(to="/admin/dashboard", v-if="loggedIn") Dashboard
</template>
<script>
import firebase from "firebase/app";
export default {
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      this.loggedIn = !!user;
      this.admin = this.loggedIn;
    });

    const pathName = window.location.pathname;

    if (pathName.indexOf("admin") === -1 || pathName.indexOf("login") === -1) {
      this.adminPage = false;
    } else {
      this.adminPage = true;
    }
  },

  data() {
    return {
      loggedIn: false,
      admin: false,
      navActive: "",
      adoptActive: false,
    };
  },
  methods: {
    async signOut() {
      try {
        const data = await firebase.auth().signOut();
        // console.log(data);
        this.$router.push("/");
      } catch (err) {
        console.error(err);
      }
    },

    showNav() {
      this.navActive = !this.navActive;
    },

    showAdditional() {
      if (this.adoptActive === false) {
        document.querySelector("#drop").dataset.set = "active";
        this.adoptActive = true;
        return;
      } else {
        document.querySelector("#drop").dataset.set = "neutral";
        this.adoptActive = false;
      }
    },
  },
};
</script>
<style lang="sass" scoped>
@import '../sass/variables'

.nav
  z-index: 100


.admin-nav
  background-color: $primary-orange

.nav-container
  height: 80px
  background-color: $primary-lighto
  display: flex
  padding: 0 50px
  justify-content: space-between
  position: relative
  z-index: 9999
  +mobile
    padding: 0 20px
    height: 60px
  .left-div
    display: flex
    justify-content: center
    align-items: center
    text-decoration: none
    .logo-container
      width: 60px
      height: 60px
      +mobile
        width: 40px
        height: 40px
      img
        width: 100%
        height: 100%
    .shop-title
      margin-bottom: 0
      margin-left: 10px
      font-family: 'Sacramento'
      color: $primary-orange
      font-weight: 300
      margin-top: 5px
      font-size: 32px
      line-height: 32px
      text-align: left
      @media (max-width: 1200px)
        font-size: 22px
        line-height: 26px
  .right-div
    display: flex
    justify-content: center
    align-items: center
    z-index: 99999
    +mobile
      img
        width: 30px
    .tabs-container
      display: flex
      justify-content: center
      align-items: center
      flex-direction: row
      height: 100%
      a, #tab3
        height: 100%
        text-decoration: none
        .tab
          display: flex
          height: 100%
          justify-content: center
          align-items: center
          text-decoration: none
          padding: 0 10px
          z-index: 999
          background-color: $primary-lighto
          &:hover, &:active, &:focus
            background-color: #fff
            p
              color: $primary-orange
            svg
              stroke: $primary-orange
              &.base
                animation: 1s navMoveRight infinite ease-out
          p
            margin: 0
            font-weight: bold
            color: $primary-orange
            font-size: 18px
            line-height: 22px
            @media (max-width: 1200px)
              font-size: 14px
              line-height: 18px
          svg
            margin: 0 0 0 6px
            width: 10px
            stroke: $primary-orange

#mtab3
  display: flex
  flex-direction: column
  overflow: hidden
  #drop
    display: flex
    flex-direction: column
    overflow: hidden
    &[data-set='neutral']
      height: 0
      transition: .5s height
    &[data-set='active']
      height: calc(60px * 2) // number of nav items
      transition: .5s height
    .inner-tab
      display: flex
      padding: 10px 60px 0 0px
      text-decoration: none
      text-transform: uppercase
      position: relative
      background-color: $primary-orange
      text-align: right
      &:hover, &:active, &:focus
        background-color: #fff
        p
          color: $primary-orange
        svg
          stroke: $primary-orange
          animation: 1s navDropMoveRight infinite ease-out
      svg
        margin: 0 0 0 6px
        width: 13px
        stroke: #fff
        position: absolute
        right: 40px
        top: 50%
        transform: translateY(-50%)
      p
        color: #fff
        font-size: 14px
        line-height: 18px
        font-weight: bold

#tab3
  position: relative
  height: 100%
  .tab
    background-color: $primary-orange
  &:hover
    .dropdown-container
      transform: translateY(127px)
      transition: transform .5s ease-in
  .dropdown-container
    background-color: $primary-lighto
    position: absolute
    left: 0
    bottom: 0
    width: 200px
    display: flex
    flex-direction: column
    text-align: left
    z-index: -1
    .inner-tab
      display: flex
      padding: 10px 20px
      text-decoration: none
      text-transform: uppercase
      position: relative
      &:hover, &:active, &:focus
        background-color: #fff
        p
          color: $primary-orange
        svg
          stroke: $primary-orange
          animation: 1s navDropMoveRight infinite ease-out
      svg
        margin: 0 0 0 6px
        width: 10px
        stroke: $primary-orange
        position: absolute
        right: 20px
        top: 50%
        transform: translateY(-50%)
      p
        color: $primary-orange
        font-size: 18px
        line-height: 22px
        font-weight: bold
        margin: 0
        @media (max-width: 1200px)
          font-size: 14px
          line-height: 22px

.mobileNav
  display: flex
  flex-direction: column
  background-color: #fff
  position: absolute
  right: 0
  top: -355px
  z-index: -1
  width: 180px
  &.active
    animation: navDown .5s ease-out
    animation-fill-mode: forwards
  &.disappear
    animation: navUp .5s ease-in
    animation-fill-mode: forwards
  .tab
    background-color: $primary-orange
    padding: 20px
    display: flex
    justify-content: flex-end
    align-items: center
    text-decoration: none
    &:hover, &:active, &:focus
      background-color: #fff
      p
        color: $primary-orange
      svg
        stroke: $primary-orange
    &.last
      border-bottom: none
    p
      color: #fff
      text-decoration: none
      font-weight: bold
      margin: 0
    svg
      margin: 0 0 0 10px
      width: 15px
      stroke: #ffffff

// #mtab3[data-set='active']
//   .mobile-dropdown
//     display: flex
//     height: 132px
//     transition: height .5s

// #mtab3[data-set='neutral']
//   .mobile-dropdown
//     display: none
//     height: 0
//     transition: height .5s

@keyframes navMoveRight
  0%
    transform: translateX(0)
  50%
    transform: translateX(3px)
  100%
    transform: translateX(0px)

@keyframes navDropMoveRight
  0%
    transform: translate(0, -50%)
  50%
    transform: translate(3px, -50%)
  100%
    transform: translate(0px, -50%)

@keyframes navDown
  0%
    transform: translateY(0)
  100%
    transform: translateY(415px)

@keyframes navUp
  0%
    transform: translateY(415px)
  100%
    transform: translateY(0)
</style>