<template lang="pug">
#donate
  .donate-container
    .banner-section
      img(src="../assets/images/donate/banner.png", loading="lazy")
    .bottom-container
      .content-cont
        h2 Fundings
        p My Pets Haven is providing shelter to over 35 dogs and 15 cats today. Rescue missions are frequently carried out to save animals from abuse, neglect, illness and injury. After a successful rescue, the animal will be hospitalised at the veterinary clinic to get the treatment they need. Once the rescued animal has been nursed back to good health, our journey in finding the perfect home for them begins. While this process could take months up to years, we will provide everything for them in the meantime that they’re taking shelter at our centre.
        p As we are not backed up by any government bodies or non-governmental organisations, Aunty Aileen used to fund for everything from her own personal savings. But thanks to continuous support from our followers, monetary contributions from the public are our main source of fundings for our rescues today. It goes to fund for all expenses from the day of rescue up until the day the rescued animal gets adopted. Your contribution will mean the world to us and our animals as every dollar received will aid us in our work tremendously.
        .img-cont
          img.desktop(src="../assets/images/donate/header.png", loading="lazy")
          img.mobile(src="../assets/images/donate/5.png", loading="lazy")
          img.mobile(src="../assets/images/donate/6.png", loading="lazy")
          img.mobile(src="../assets/images/donate/7.png", loading="lazy")
          img.mobile(src="../assets/images/donate/8.png", loading="lazy")
        .center-cont
          .list-cont
            p.center Your donation will fund:
            .ul-cont
              ul
                li
                  p Rescue missions
                li
                  p Spaying & Neutering
                li
                  p Vaccinations
                li
                  p Treatment and Hospitalisation
              ul.right
                li
                  p On-going care
                li
                  p Food such as rice, raw meat and pet kibbles
                li
                  p Essentials such as cages and cleaning supplies
                li
                  p Feeding the strays
          p.center We could only do so much because of the love and support from people like you! Your small act of generosity will give stray animals a chance to live a better life. Save and change a life by donating today.
      .content-cont
        h2 Donation methods
        p If you wish to make a contribution today, you can do so through the following method:
        .methods-box
          //- .box
          //-   h3 Direct Transfer
          //-   p 7603999750
          //-   p CIMB Bank Berhad
          //-   p Lee Sor Geok
          img.boxImg(src="../assets/images/donateNow.jpeg")
    .bottom-banner
      .banner-cont
      .center-cont
        h3 OUR WORK
        p Learn more about our work and what your contribution will be funding for.
        a(href="/ourwork")
          button Our Work
</template>
<script>
export default {
  name: "Donate",
  metaInfo: {
    title: "Donate",
    meta: [
      {
        name: "description",
        content:
          "Donate to help fund for our stray rescue work and transform lives. Monetary contributions from the public are our main source of fundings for our rescues today.",
      },
      {
        name: "keywords",
        content:
          "mypetshaven, dogs, adopt, cats, adoption, shelter, volunteer, puppies, kittens, neuter, rescue, animals, nurture, injure, donate, pets, abandon, haven, aileen, setia, alam, strays, companion, declaw",
      },
    ],
  },
  methods: {
    donateClick() {
      this.$gtag.event("donate-clicked", {
        event_category: "button",
        event_label: "Donate",
        value: "donation_link_opened",
      });
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/variables.sass'
#donate
  .banner-section
    width: 100%
    img
      width: 100%
  .bottom-container
    max-width: 1100px
    padding: 0 20px
    margin: 30px auto 0
    +mobile
      max-width: 500px
    .content-cont
      margin: 0 auto 30px auto
      text-align: left
      width: 100%
      .center-cont
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        margin: 20px auto
        +mobile
          justify-content: flex-start
          align-items: flex-start
      .img-cont
        display: flex
        flex-direction: column
        img
          width: 100%
      h2
        font-size: 32px
        line-height: 36px
        color: $primary-orange
        margin-bottom: 10px
        +mobile
          font-size: 24px
          line-height: 28px
      p
        color: $primary-grey
        font-size: 16px
        line-height: 22px
        +mobile
          font-size: 14px
          line-height: 18px
      .methods-box
        display: flex
        justify-content: center
        text-align: center
        +mobile
          flex-wrap: wrap
        .box
          margin: 10px 20px 0 20px
          background-color: $primary-orange
          border-radius: 30px
          width: 200px
          height: 200px
          display: flex
          align-items: center
          flex-direction: column
          justify-content: center
          &:hover
            button
              transform: scale(1.1)
          h3
            color: #fff
            font-weight: 400
            margin-bottom: 10px
          p
            margin-bottom: 5px
            color: #fff
          button
            background-color: #FFCC00
            border-radius: 20px
            padding: 5px 20px
            margin-top: 20px
            font-weight: 500
            font-family: 'RobotoSlab'
            color: #9E321A
        .boxImg
          margin: 10px 20px 0 20px
          background-color: $primary-orange
          border-radius: 30px
          width: 350px
          height: 650px
          display: flex
          align-items: center
          flex-direction: column
          justify-content: center
          &:hover
            button
              transform: scale(1.1)

.ul-cont
  display: flex
  margin: 20px auto
  +mobile
    flex-direction: column
    margin: 0 auto
  ul
    &.right
      margin-left: 50px
      +mobile
        margin-left: 0
    li
      p
        text-align: left

.center-cont
  p
    &.center
      text-align: center
      +mobile
        text-align: left

.bottom-banner
  width: 100%
  height: 400px
  position: relative
  margin-top: 50px
  // background-image: url('../assets/images/our-work/header.png')
  // background-size: cover
  // background-position: center center
  // filter: brightness(50%)
  // +mobile
  //   background-image: url('https://via.placeholder.com/600x400/333333')
  .banner-cont
    background-image: url('../assets/images/our-work/header.png')
    background-size: cover
    background-position: center center
    filter: brightness(50%)
    width: 100%
    height: 100%
    z-index: 0
  .center-cont
    position: absolute
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    height: 100%
    max-width: 60%
    margin: 0 auto
    z-index: 5
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    +mobile
      max-width: 100%
      width: 80%
    h3
      color: #fff
      font-size: 36px
      line-height: 40px
      margin-bottom: 5px
      +mobile
        font-size: 24px
        line-height: 28px
    p
      color: #fff
      font-size: 18px
      line-height: 22px
      margin-bottom: 30px
      +mobile
        font-size: 14px
        line-height: 18px
    button
      background-color: $primary-orange
      padding: 10px 20px
      font-weight: bold
      font-family: 'RobotoSlab'
      color: #fff
</style>
