import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta'
import Login from '../views/Login.vue';
import Dashboard from '../views/Dashboard.vue';
import Home from '../views/Home.vue';
import OurWork from '../views/OurWork.vue';
import ArticlePage from '../views/ArticlePage.vue';
import AnnouncementPage from '../views/Announcements.vue';
import HomeCMS from '../views/HomeCMS.vue';
import Adoption  from '../views/Adoption.vue';
import Donate  from '../views/Donate.vue';
import ContactUs  from '../views/ContactUs.vue';
import AboutUs  from '../views/AboutUs.vue';
import AdoptionPolicy  from '../views/AdoptionPolicy.vue';
import enquiryList from '../views/AdoptionEnquiryList.vue';
import firebase from "firebase";
import addAnimal from '../views/AnimalCreate.vue';
import editAnimal from '../views/AnimalEdit.vue';
import animalList from '../views/AnimalList.vue';
import updateCreate from '../views/UpdateCreate.vue';
import updateList from '../views/UpdateList.vue';
import updateEdit from '../views/UpdateEdit.vue';
import blogCreate from '../views/BlogCreate.vue';
import blogList from '../views/BlogList.vue';
import blogEdit from '../views/BlogEdit.vue';
import volunteer from '../views/VolunteerForm.vue';
import volunteerList from '../views/VolunteerList.vue';
import volunteerEdit from '../views/VolunteerEdit.vue';
import notFound from '../views/NotFound.vue';
import privacyPolicy from '../views/PrivacyPolicy.vue';
import VueGtag from "vue-gtag";
Vue.use(Router);
Vue.use(Meta);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/admin/homeCMS',
      name: 'homeCMS',
      component: HomeCMS,
    },
    {
      path: '/volunteer',
      name: 'volunteer',
      component: volunteer,
    },
    {
      path: '/ourwork',
      name: 'OurWork',
      component: OurWork,
    },
    {
      path: '/ourwork/:id',
      name: 'ArticlePage',
      component: ArticlePage,
    },
    {
      path: '/announcements/:id',
      name: 'AnnouncementPage',
      component: AnnouncementPage,
    },
    {
      path: '/admin/volunteerList',
      name: 'volunteerList',
      component: volunteerList,
      meta: {requiresAuth: true}
    },
    {
      path: '/admin/volunteerEdit/:id',
      name: 'volunteerEdit',
      component: volunteerEdit,
      meta: {requiresAuth: true}
    },
    {
      path: '/admin/addAnimal',
      name: 'addAnimal',
      component: addAnimal,
      meta: {requiresAuth: true}
    },
    {
      path: '/admin/editAnimal/:id',
      name: 'editAnimal',
      component: editAnimal,
      meta: {requiresAuth: true}
    },
    {
      path: '/admin/animalList',
      name: 'animalList',
      component: animalList,
      meta: {requiresAuth: true}
    },
    {
      path: '/admin/updateCreate',
      name: 'updateCreate',
      component: updateCreate,
      meta: {requiresAuth: true}
    },
    {
      path: '/admin/updateList',
      name: 'updateList',
      component: updateList,
      meta: {requiresAuth: true}
    },
    {
      path: '/admin/updateEdit/:id',
      name: 'updateEdit',
      component: updateEdit,
      meta: {requiresAuth: true}
    },

    {
      path: '/admin/blogCreate',
      name: 'blogCreate',
      component: blogCreate,
      meta: {requiresAuth: true}
    },

    {
      path: '/admin/blogList',
      name: 'blogList',
      component: blogList,
      meta: {requiresAuth: true}
    },
    {
      path: '/admin/blogEdit/:id',
      name: 'blogEdit',
      component: blogEdit,
      meta: {requiresAuth: true}
    },
    {
      path: '/admin/enquiryList',
      name: 'enquiryList',
      component: enquiryList,
      meta: {requiresAuth: true}
    },

    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/adopt',
      name: 'adopt',
      component: Adoption,
    },
    {
      path: '/privacy-policy',
      name: 'privacyPolicy',
      component: privacyPolicy,
    },
    {
      path: '/contactus',
      name: 'ContactUs',
      component: ContactUs,
    },
    {
      path: '/aboutus',
      name: 'AboutUs',
      component: AboutUs,
    },
    {
      path: '/donate',
      name: 'Donate',
      component: Donate,
    },
    {
      path: '/adoptionPolicy',
      name: 'adoptPolicy',
      component: AdoptionPolicy,
    },
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      meta: {requiresAuth: true}
    },
    {
      path: "*",
      component: notFound,
    }
]
});

Vue.use(VueGtag, {
  config: {
    id: 'G-WTBKM74Y5N'
  }
}, router)

router.beforeEach((to,from,next)=>{
  const requiresAuth = to.matched.some(record =>  record.meta.requiresAuth);
  const isAuthenticated = firebase.auth().currentUser;
  if((requiresAuth ) && !isAuthenticated){
    next("/login");
  }else{
    next();
  }
})

export default router