<template lang="pug">
v-container(fluid, ma-0, pa-0)
  .overlay
  v-overlay(v-model="overlay", v-if="viewedAnimal !== null", :absolute="abs")
    v-row.overlayRow(no-gutters, justify="center", align="center")
      v-btn.overlayLeftBtn(@click="goBack", color="#FF9785", rounded, large) Back

      v-card.viewedCard.rounded-xl(color="#FDE5D8")
        v-row(
          v-if="viewedAnimal.imageList.length !== 0",
          justify="center",
          no-gutters,
          align="center"
        )
          v-btn.leftBtn(
            v-if="!noPictures",
            @click="goBefore",
            small,
            fab,
            color="#FF9785"
          )
            v-icon mdi-arrow-left-drop-circle-outline

          v-img.showedPicture.picture(
            max-width="70%",
            :src="currentImg.url",
            @click="select(currentImg.url)",
            style="cursor: pointer"
          )

          v-btn.rightBtn(
            v-if="!noPictures",
            @click="goFront",
            small,
            fab,
            color="#FF9785"
          )
            v-icon mdi-arrow-right-drop-circle-outline

        v-row(v-else, justify="center", no-gutters)
          v-img.showedPicture.picture(
            src="https://firebasestorage.googleapis.com/v0/b/petshaven-d655e.appspot.com/o/cat.jpg?alt=media&token=b8b2c365-b6dd-4fdf-b3b3-98baf7f8895e"
          )

        v-row.desc(align="center", justify="center", no-gutters)
          v-card-text.text-start.textbox(align="center", justify="center")
            h2.name
              | {{ viewedAnimal.name }}
            p.special
              | <b>Gender: {{ viewedAnimal.gender }}</b>
            p.special
              | <b>Age: {{ viewedAnimal.age }} old</b>
            p.special
              | <b>My Story: </b>
            .story-div
              p {{ viewedAnimal.description }}

        v-card-actions.overlayAction
          v-spacer
          v-btn.adoptBtn(raised, rounded, color="#FF9785", to="/donate") Sponsor Me

          v-spacer.space
          v-btn.adoptBtn(
            raised,
            rounded,
            color="#FF9785",
            to="/adoptionPolicy"
          ) Enquire Now

          v-spacer

      v-btn.overlayRightBtn(@click="goNext", color="#FF9785", rounded, large) Next

  //- v-img(
  //-   :lazy-src="require('../assets/images/adopt/banner.png')",
  //-   max-height="600",
  //-   max-width="500%",
  //-   :src="require('../assets/images/adopt/banner.png')"
  //- )
  .banner-section
    img(src="../assets/images/adopt/banner.png", loading="lazy")

  v-container(fluid)
    v-row(justify="center", no-gutters)
      v-col(cols="12", sm="8")
        p.adoptText All the animals shown below are up for adoption! Give our wonderful animals a chance at a better life after having gone through some tough times on the streets. You can save a life by adopting today.

    v-row(justify="center", no-gutters)
      v-btn-toggle#toggle.options(v-model="toggleBtn", rounded)
        v-btn.btns(
          active-class="test",
          elevation="2",
          outlined,
          raised,
          rounded,
          color="#FF9785",
          @click="viewCats"
        ) Cats

        v-btn.btns(
          active-class="test",
          elevation="2",
          outlined,
          raised,
          rounded,
          color="#FF9785",
          @click="viewDogs"
        ) Dogs

    v-row.justify-center
      v-btn-toggle#toggle(v-model="toggleBtn2", rounded)
        v-btn.btns(
          active-class="test",
          elevation="2",
          outlined,
          raised,
          rounded,
          color="#FF9785",
          @click="filterMale"
        ) Male

        v-btn.btns(
          active-class="test",
          elevation="2",
          outlined,
          raised,
          rounded,
          color="#FF9785",
          @click="filterFemale"
        ) Female

    v-container(fill-height)
      v-row(
        justify="center",
        align="center",
        v-if="!dog && !cat && !male & !female"
      )
        v-col.d-flex.justify-center.align-center(
          cols="12",
          sm="6",
          lg="3",
          v-for="(animal, index) in Animals",
          :key="animal.key"
        )
          v-card.animalCard(color="#FDE5D8")
            v-row
              v-avatar.picture(min-height="50", min-width="50", size="220")
                v-img(:src="animal.thumbnail.url")

            v-row
              v-card-text.description
                h2 {{ animal.name }}
                p {{ animal.gender }}
                p {{ animal.age }} old

            v-card-actions.cardActions
              v-spacer
              v-btn.actionBtn(
                @click="viewAnimal(animal, index)",
                rounded,
                large,
                color="#FF9785"
              ) Learn More
              v-spacer

      v-row(v-else, justify="center", align="center", width="80%")
        v-col.d-flex.justify-center.align-center(
          cols="12",
          sm="6",
          lg="3",
          v-for="(animal, index) in SelectedAnimals",
          :key="animal.key"
        )
          v-card.animalCard(color="#FDE5D8")
            v-row
              v-avatar.picture(min-height="50", min-width="50", size="220")
                v-img(:src="animal.thumbnail.url")

            v-row
              v-card-text.description
                h2 {{ animal.name }}
                p {{ animal.gender }}
                p {{ animal.age }} old

            v-card-actions.cardActions
              v-spacer
              v-btn.actionBtn(
                @click="viewAnimal(animal, index)",
                rounded,
                large,
                color="#FF9785"
              ) Learn More
              v-spacer
</template>

<script>
import { db } from "../main";
export default {
  data() {
    return {
      abs: true,
      currentImg: null,
      currentIndex: 0,
      currentAnimal: 0,
      currentAdoptEnq: "",
      Animals: [],
      SelectedAnimals: [],
      genderAnimals: [],
      toggleBtn: 2,
      toggleBtn2: 2,
      overlay: false,
      viewedAnimal: null,
      cat: false,
      dog: false,
      male: false,
      female: false,
    };
  },
  metaInfo: {
    title: "Adoption",
    meta: [
      {
        name: "description",
        content:
          "All our animals in this page are up for adoption. Our cats and dogs are all rescued and nurtured until they're fit for adoption! Give them a place to call their furever home.",
      },
      {
        name: "keywords",
        content:
          "mypetshaven, dogs, adopt, cats, adoption, shelter, volunteer, puppies, kittens, neuter, rescue, animals, nurture, injure, donate, pets, abandon, haven, aileen, setia, alam, strays, companion, declaw",
      },
    ],
  },
  watch: {
    overlay(val) {
      if (val) {
        document.addEventListener("mousedown", this.mousedownHandler);
      } else {
        document.removeEventListener("mousedown", this.mousedownHandler);
      }
    },
  },
  computed: {
    noPictures() {
      if (this.viewedAnimal !== null) {
        if (this.viewedAnimal.imageList.length > 1) {
          return false;
        } else {
          return true;
        }
      }
      return true;
    },
  },
  async created() {
    db.collection("animals").onSnapshot(async (snapshotChange) => {
      this.Animals = [];
      await snapshotChange.forEach((doc) => {
        this.Animals.push({
          key: doc.id,
          name: doc.data().name,
          type: doc.data().type,
          gender: doc.data().gender,
          age: doc.data().age,
          description: doc.data().description,
          imageList: doc.data().imageList,
          thumbnail: doc.data().thumbnail,
          dateUploaded: doc.data().dateUploaded
        });
      });
      this.Animals.sort((a,b)=> {return b.dateUploaded - a.dateUploaded});
    });
  },
  methods: {
    goNext() {
      this.currentIndex = 0;
      this.currentAnimal += 1;
      if (!this.dog && !this.cat && !this.male & !this.female) {
        if (this.currentAnimal >= this.Animals.length) {
          this.currentAnimal = 0;
        }
        this.viewedAnimal = this.Animals[this.currentAnimal];
      } else {
        if (this.currentAnimal >= this.SelectedAnimals.length) {
          this.currentAnimal = 0;
        }
        this.viewedAnimal = this.SelectedAnimals[this.currentAnimal];
      }
      this.currentImg = this.viewedAnimal.imageList[this.currentIndex];
    },
    goBack() {
      this.currentIndex = 0;
      this.currentAnimal -= 1;
      if (!this.dog && !this.cat && !this.male & !this.female) {
        if (this.currentAnimal < 0) {
          this.currentAnimal = this.Animals.length - 1;
        }
        this.viewedAnimal = this.Animals[this.currentAnimal];
      } else {
        if (this.currentAnimal < 0) {
          this.currentAnimal = this.SelectedAnimals.length - 1;
        }
        this.viewedAnimal = this.SelectedAnimals[this.currentAnimal];
      }
      this.currentImg = this.viewedAnimal.imageList[this.currentIndex];
    },
    mousedownHandler(e) {
      if (
        e.target.classList.contains("v-overlay__scrim") ||
        e.target.classList.contains("overlayRow")
      ) {
        this.overlay = false;
        this.currentIndex = 0;
      }
    },
    filterMale() {
      if (this.male) {
        this.male = false;
      } else {
        this.male = true;
      }
      this.female = false;

      if (!this.male) {
        if (this.dog) {
          this.SelectedAnimals = this.Animals.filter(
            (animal) => animal.type === "Dog"
          );
        } else if (this.cat) {
          this.SelectedAnimals = this.Animals.filter(
            (animal) => animal.type === "Cat"
          );
        } else {
          this.SelectedAnimals = [];
        }
      } else {
        if (this.dog) {
          this.SelectedAnimals = this.Animals.filter(
            (animal) => animal.type === "Dog" && animal.gender === "Male"
          );
        } else if (this.cat) {
          this.SelectedAnimals = this.Animals.filter(
            (animal) => animal.type === "Cat" && animal.gender === "Male"
          );
        } else {
          this.SelectedAnimals = this.Animals.filter(
            (animal) => animal.gender === "Male"
          );
        }
      }
    },
    filterFemale() {
      if (this.female) {
        this.female = false;
      } else {
        this.female = true;
      }
      this.male = false;

      if (!this.female) {
        if (this.dog) {
          this.SelectedAnimals = this.Animals.filter(
            (animal) => animal.type === "Dog"
          );
        } else if (this.cat) {
          this.SelectedAnimals = this.Animals.filter(
            (animal) => animal.type === "Cat"
          );
        } else {
          this.SelectedAnimals = [];
        }
      } else {
        if (this.dog) {
          this.SelectedAnimals = this.Animals.filter(
            (animal) => animal.type === "Dog" && animal.gender === "Female"
          );
        } else if (this.cat) {
          this.SelectedAnimals = this.Animals.filter(
            (animal) => animal.type === "Cat" && animal.gender === "Female"
          );
        } else {
          this.SelectedAnimals = this.Animals.filter(
            (animal) => animal.gender === "Female"
          );
        }
      }
    },
    goBefore() {
      this.currentIndex =
        (this.currentIndex - 1) % this.viewedAnimal.imageList.length;
      if (this.currentIndex < 0) {
        this.currentIndex += this.viewedAnimal.imageList.length;
      }
      this.currentImg = this.viewedAnimal.imageList[this.currentIndex];
    },
    goFront() {
      this.currentIndex =
        (this.currentIndex + 1) % this.viewedAnimal.imageList.length;
      this.currentImg = this.viewedAnimal.imageList[this.currentIndex];
    },
    begoneOverlay() {
      this.currentIndex = 0;
      this.overlay = false;
    },
    viewCats() {
      if (this.cat) {
        this.cat = false;
      } else {
        this.cat = true;
      }
      this.dog = false;
      if (!this.cat) {
        if (this.male) {
          this.SelectedAnimals = this.Animals.filter(
            (animal) => animal.gender === "Male"
          );
        } else if (this.female) {
          this.SelectedAnimals = this.Animals.filter(
            (animal) => animal.gender === "Female"
          );
        } else {
          this.SelectedAnimals = [];
        }
      } else {
        if (this.male) {
          this.SelectedAnimals = this.Animals.filter(
            (animal) => animal.type === "Cat" && animal.gender === "Male"
          );
        } else if (this.female) {
          this.SelectedAnimals = this.Animals.filter(
            (animal) => animal.type === "Cat" && animal.gender === "Female"
          );
        } else {
          this.SelectedAnimals = this.Animals.filter(
            (animal) => animal.type === "Cat"
          );
        }
      }
    },
    viewDogs() {
      if (this.dog) {
        this.dog = false;
      } else {
        this.dog = true;
      }
      this.cat = false;

      if (!this.dog) {
        if (this.male) {
          this.SelectedAnimals = this.Animals.filter(
            (animal) => animal.gender === "Male"
          );
        } else if (this.female) {
          this.SelectedAnimals = this.Animals.filter(
            (animal) => animal.gender === "Female"
          );
        } else {
          this.SelectedAnimals = [];
        }
      } else {
        if (this.male) {
          this.SelectedAnimals = this.Animals.filter(
            (animal) => animal.type === "Dog" && animal.gender === "Male"
          );
        } else if (this.female) {
          this.SelectedAnimals = this.Animals.filter(
            (animal) => animal.type === "Dog" && animal.gender === "Female"
          );
        } else {
          this.SelectedAnimals = this.Animals.filter(
            (animal) => animal.type === "Dog"
          );
        }
      }
    },
    viewAnimal(animal, index) {
      this.currentAnimal = index;
      this.currentImg = animal.imageList[this.currentIndex];
      this.viewedAnimal = animal;
      this.currentAdoptEnq = "/adoptionEnquiry/" + animal.key;
      this.overlay = true;
      if(window.innerWidth > 700){
        window.scrollTo(window.innerWidth / 2, document.getElementById("app").scrollHeight/2.5 )
      }
      else{
        window.scrollTo(window.innerWidth / 2, document.getElementById("app").scrollHeight/2.3 )
      }

      // if (window.innerHeight < 941 && window.innerHeight > 630) {
      //   window.scrollTo(window.innerWidth / 2, window.innerHeight + 700);
      // } else if (window.innerHeight < 630) {
      //   window.scrollTo(window.innerWidth / 2, window.innerHeight + 2800);
      // } else {
      //   window.scrollTo(window.innerWidth / 2, window.innerHeight / 1.4);
      // }
    },

    select(url) {
      // console.log(url);
      window.location.href = url;
    },
  },
};
</script>

<style lang="sass">
@import '../sass/variables.sass'

.adoptText
  color: $primary-orange
  font-size: 24px !important
  font-weight: bold !important
  line-height: 24px
  margin-bottom: 40px !important
  +mobile
    font-size: 24px !important
    line-height: 24px
    margin: 0 10px
    margin-bottom: 30px !important

.btns
  background: white
  width: 100px
  letter-spacing: 0px !important
  font-size: 18px !important
  font-weight: bold !important

.v-btn-toggle .test
  background: rgb(255,151,133) !important
  color: white !important

.animalCard
  width: 700px
  max-width: 100%
  min-height: 400px
  max-height: 600px

.banner-section
  width: 100%
  img
    width: 100%

// h2
//   margin-bottom: 10%

.picture
  margin: 20px auto 0 auto

.showedPicture
  height: 100%
  object-fit: fill
  width: 100%

.actionBtn
  font-family: 'RobotoSlab' !important
  margin-bottom: 5%
  padding-left: 20px !important
  padding-right: 20px !important
  color: white !important
  letter-spacing: 0px !important

.viewedCard
  +desktop
    width: 50%
    border-radius: 50%
  +mobile
    width: 100vw

.leftBtn
  +desktop
    margin-left: 5%
  +mobile
    margin-left: 2%

.rightBtn
  +desktop
    margin-right: 5%
  +mobile
    margin-right: 2%

.name
  color: pink
  margin-bottom: 5%

.text
  color: black

.text-start
  h2
    &.name
      margin-left: 20px !important
      font-size: 32px
      line-height: 36px
  p
    &.special
      margin-left: 20px
      font-size: 18px
      line-height: 22px

.desc
  width: 70%
  margin: 0 15% !important
  +mobile
    width: 90%
    margin: 0 5% !important
  .story-div
    display: flex
    width: 100%
    background: #fff
    padding: 10px 20px
    border-radius: 20px
    position: relative
    &::-webkit-scrollbar
      width: 10px

    &::-webkit-scrollbar-track
      background-color: transparent
      border-radius: 10px

    &::-webkit-scrollbar-thumb
      background-color: $primary-orange
      border-radius: 10px

  h2
    color: $primary-orange
    margin: 0 0 10px 0 !important
    font-size: 28px
  p
    color: $primary-grey
    margin-bottom: 5px !important
    font-size: 18px
    line-height: 26px

.adoptBtn
  margin-bottom: 5%
  font-size: 16px !important
  +desktop
    width: 200px
    letter-spacing: 0px !important
    padding-top: 50px
  +mobile
    width: 45%
    letter-spacing: 0px !important

.overlayAction
  padding-top: 0%

.description
  margin: 18px auto 18px auto !important
  padding: 0px !important
  h2
    font-size: 28px
    font-weight: bold
    line-height: 32px
    margin-bottom: 10px
    color: $primary-brown
  p
    font-size: 18px
    margin: 0 0 0 0 !important
    line-height: 18px
    color: $primary-brown

.overlayRow
  +desktop
    width: 100vw
  +mobile
    padding-bottom: 800px !important
    width: 100vw
    display: flex
    flex-direction: column

.options
  margin-bottom: 1%

.overlayRightBtn
  +desktop
    width: 12%
    margin-left: 15px
  +mobile
    width: 50%
    margin-top: 10%

.overlayLeftBtn
  +desktop
    width: 12%
    margin-right: 15px
  +mobile
    width: 50%
    margin-bottom: 10%
</style>