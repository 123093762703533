import Vue from 'vue'
import App from './App.vue'
import router from './router'
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: "AIzaSyCM8mGZGLXQN29NN8whR-ugQssbUKVgu3c",
  authDomain: "petshaven-d655e.firebaseapp.com",
  databaseURL: "https://petshaven-d655e.firebaseio.com",
  projectId: "petshaven-d655e",
  storageBucket: "petshaven-d655e.appspot.com",
  messagingSenderId: "600042707338",
  appId: "1:600042707338:web:903add642ffc20b9de1073",
  measurementId: "G-RSNSZHJ3NG"
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();

let app;

firebase.auth().onAuthStateChanged(() => {
  // console.log(user);
  if(!app){
    app  = new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
});
