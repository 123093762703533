<template>
  <v-container>
    <v-row class="justify-center" no-gutters>
      <v-card class="justify-center card">
        <v-card-text>
          <h3 class="lab text-h3 text--primary">Add Animal</h3>
          <v-form ref="form">
            <label class="labels text-h5 text-start">Name</label>
            <v-text-field
              outlined
              v-model="animal.name"
              placeholder="Enter Name"
              required
            ></v-text-field>

            <label class="labels text-h5 text-start">Animal</label>
            <v-select
              outlined
              :items="type"
              placeholder="Select Animal"
              v-model="animal.type"
              required
            ></v-select>

            <label class="labels text-h5 text-start">Gender</label>
            <v-select
              outlined
              :items="gender"
              placeholder="Select Gender"
              v-model="animal.gender"
              required
            ></v-select>

            <label class="labels text-h5 text-start"
              >Age (Type the years/month as well)</label
            >
            <v-text-field
              outlined
              v-model="animal.age"
              placeholder="Enter age here"
              class="text-h5"
              required
            ></v-text-field>

            <label class="labels text-h5 text-start">Description</label>
            <v-textarea
              outlined
              placeholder="Write the description here"
              class="text-h5"
              id="editor"
              v-model="animal.description"
            >
            </v-textarea>

            <v-container>
              <v-row justify="start">
                <v-col justify="start">
                  <label class="text-h5 lab text--primary labels text-center"
                    >Add Thumbnail Photo (Only 1 Picture)</label
                  >
                  <input
                    type="file"
                    ref="input1"
                    @change="previewImage3"
                    accept="image/*"
                    multiple
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-container class="form-group" v-if="thumbnail.length !== 0">
              <v-row justify="center">
                <v-col cols="12">
                  <v-card>
                    <v-img :src="thumbnail[0].url" class="preview"></v-img>
                    <v-btn @click.prevent="onDelete3(thumbnail[0].reference)"
                      >Delete</v-btn
                    >
                  </v-card>
                </v-col>
              </v-row>
            </v-container>

            <v-container>
              <v-row>
                <v-col>
                  <label class="display-1 lab text--primary labels"
                    >Add More Photos</label
                  >
                  <input
                    type="file"
                    ref="input1"
                    @change="previewImage"
                    accept="image/*"
                    multiple
                  />
                </v-col>
              </v-row>
            </v-container>

            <v-container class="form-group" v-if="imageList.length > 0">
              <v-row justify="center">
                <v-col
                  cols="6"
                  sm="4"
                  v-for="image in imageList"
                  :key="image.reference"
                >
                  <v-card>
                    <v-img :src="image.url" class="preview"></v-img>
                    <v-btn @click.prevent="onDelete(image.reference)"
                      >Delete</v-btn
                    >
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions class="justify-center">
              <v-btn
                :disabled="allowButton"
                @click="onFormSubmit"
                type="submit"
                class="btn btn-primary btn-block"
                >Add Animal</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "../main";
import firebase from "firebase";
export default {
  data() {
    return {
      thumbnail: [],
      type: ["Cat", "Dog"],
      gender: ["Male", "Female"],
      animal: {
        name: "",
        type: "",
        gender: "",
        age: "",
        description: "",
      },
      imageList: [],
      imageData3: null,
      imageData: null,
    };
  },
  computed: {
    allowButton() {
      return !(
        this.animal.name.length > 0 &&
        this.animal.type.length > 0 &&
        this.animal.gender.length > 0 &&
        this.animal.age.length > 0
      );
    },
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    onFormSubmit(event) {
      event.preventDefault();
      this.animal["imageList"] = this.imageList;
      this.animal["dateUploaded"] = Date.now()

      this.animal["thumbnail"] = this.thumbnail[0];

      db.collection("animals")
        .add(this.animal)
        .then(() => {
          alert("Animal successfully created!");
          this.animal.name = "";
          this.animal.type = "";
          this.animal.gender = "";
          this.animal.age = 0;
          this.animal.description = "";
          this.thumbnail = [];
          this.imageList = [];
          this.animal.imageList = [];
          this.imageData = null;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    previewImage(event) {
      this.uploadValue = 0;
      // console.log(event.target.files.length);
      this.imageData = event.target.files;
      this.onUpload();
    },

    onUpload() {
      for (this.data of this.imageData) {
        const name = Date.now() + this.data.name;
        const storageRef = firebase.storage().ref(name).put(this.data);
        storageRef.on(
          `state_changed`,
          (snapshot) => {
            this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.error(error);
          },
          () => {
            this.uploadValue = 100;
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              this.imageList.push({ reference: name, url: url });
            });
          }
        );
      }
    },
    onDelete(reference) {
      // console.log("hello");

      for (var i = this.imageList.length - 1; i >= 0; i--) {
        // console.log(this.imageList[i].reference);
        if (this.imageList[i].reference === reference) {
          this.imageList.splice(i, 1);
        }
      }
      // console.log(this.imageList);
      const storageRef = firebase.storage().ref();
      var deletedRef = storageRef.child(reference);
      deletedRef
        .delete()
        .then(function () {
          console.log("DELETED " + reference + " !");
          // File deleted successfully
        })
        .catch(function (error) {
          // Uh-oh, an error occurred!
          console.error(error);
        });
    },

    previewImage3(event) {
      this.uploadValue = 0;
      // console.log(event.target.files.length);
      if (event.target.files.length > 1) {
        alert.log("Too many files");
      } else {
        this.imageData3 = event.target.files;
        this.onUpload3();
      }
    },
    onUpload3() {
      for (this.data of this.imageData3) {
        const name = Date.now() + this.data.name;
        const storageRef = firebase.storage().ref(name).put(this.data);
        storageRef.on(
          `state_changed`,
          (snapshot) => {
            this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.error(error.message);
          },
          () => {
            this.uploadValue = 100;
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              if (this.thumbnail.length !== 0) {
                const StorageRef = firebase.storage().ref();
                var deletedRef = StorageRef.child(this.thumbnail[0].reference);
                deletedRef
                  .delete()
                  .then(function () {
                    console.log(
                      "DELETED " + this.mainImage[0].reference + " !"
                    );
                    // File deleted successfully
                  })
                  .catch(function (error) {
                    // Uh-oh, an error occurred!
                    console.error(error);
                  });
              }
              this.thumbnail = [];
              this.thumbnail.push({ reference: name, url: url });
            });
          }
        );
      }
    },
    onDelete3(reference) {
      this.thumbnail = [];
      // console.log(this.imageList);
      const storageRef = firebase.storage().ref();
      var deletedRef = storageRef.child(reference);
      deletedRef
        .delete()
        .then(function () {
          console.log("DELETED " + reference + " !");
          // File deleted successfully
        })
        .catch(function (error) {
          // Uh-oh, an error occurred!
          console.error(error);
        });
    },
  },
};
</script>
<style lang="sass" scoped>
@import '../sass/variables.sass'
.btn-primary
  margin-right: 12px
label
  display: block
.card
  +desktop
    width: 60%
  +mobile
    width: 100%
.labels
  margin-bottom: 1%
.lab
  margin-bottom: 5%

.preview
  height: 175px
</style>>
