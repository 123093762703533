import { render, staticRenderFns } from "./Announcements.vue?vue&type=template&id=e204c062&scoped=true&lang=pug&"
import script from "./Announcements.vue?vue&type=script&lang=js&"
export * from "./Announcements.vue?vue&type=script&lang=js&"
import style0 from "./Announcements.vue?vue&type=style&index=0&id=e204c062&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e204c062",
  null
  
)

export default component.exports