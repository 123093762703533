<template lang="pug">
#aboutus
  .wrapper
    .banner-section
      img(src="../assets/images/about-us/banner.png", loading="lazy")
      //- img.mobile(src="https://via.placeholder.com/700x500/333333")
    .bottom-container
      .information-wrapper
        h1 Our Founder
        .details
          .image-cont
            img(
              src="../assets/images/about-us/auntie_aileen.jpg",
              loading="lazy"
            )
          .text-container.left-margin
            p Miss Aileen Lee Sor Geok or more commonly known as Aunty Aileen, is the founder of My Pets Haven. Aunty Aileen was previously a pub owner before she discovered her passion for animals. It was only after she started working for a pet shop when she closed down her pub that she found her love for animals. After working in the pet shop for x years, she wasn’t really happy with the management. She could tell the boss didn't have the animals' best interests at heart. He denied Aunty Aileen's requests to walk the dogs that were kept in cages too small for them to even stretch their legs. Being the rebel she is, she took the dogs out for a walk whenever the boss wasn’t around. One day as she was walking the dogs, she saw that there were new vacant shop lots that were up just outside the mall where she worked at and that was when she got the idea of starting her own pet shop.
            p Hence, on June 7th 2011, My Pets Centre was open for business and has been operating ever since. It was renamed to My Pets Haven 2 years later. Now, you would wonder: Isn’t My Pets Haven a shelter instead of a business? Well, My Pets Haven is exactly what its name suggests; we are a haven for animals that we had rescued off the streets. We serve as a temporary shelter for these animals until they get adopted and we provide services such as grooming, boarding, and pet food sales to pay for the rent of our centre. All our rescue work is carried out independently and funded by Aunty Aileen’s personal savings and donations from the public.
            p However, it didn’t use to be like this when the doors were first opened at My Pets Haven. Being in the pet sales industry, Aunty Aileen only knew how to sell animals at that point in time as awareness around animal adoption and welfare wasn’t really advocated for. It was only when ‘Wong Choy’ walked in the centre that things started to change. Wong Choy (‘Lucky Wong’ in English) was a stray dog which wandered into our centre one day. It was such a peculiar incident, but Aunty Aileen decided to take him in. After spending sometime being in our centre, Wong Choy got adopted! It was then she realised she could actually do more for the stray animals living on the streets; she could rehabilitate and rehome them! Each day since then, she's only grown more and more passionate towards helping animals in need and started rescuing and rehoming more and more animals off the streets. Alas, Aunty Aileen found her true calling in life.
      .information-wrapper
        h1 Our Mission
        .details
          .infographic
            img.desktop(
              src="../assets/images/about-us/rehome.png",
              loading="lazy"
            )
            img.mobile(src="../assets/images/about-us/1.png", loading="lazy")
            img.mobile(src="../assets/images/about-us/2.png", loading="lazy")
            img.mobile(src="../assets/images/about-us/3.png", loading="lazy")
            img.mobile(src="../assets/images/about-us/4.png", loading="lazy")
      .information-wrapper.last
        h1 Our Vision
        .details
          .text-container
            p Our vision is to expand our shelter’s capacity to rescue more animals and rehome them. We aim to do more for the stray animals to give them another chance at a better life. We have saved, vaccinated, sterilised and rehomed over a thousand stray animals since we began our rescuing efforts. Ultimately, we are striving to curb negligence towards stray animals as well as control the stray population in our community. We still lack the resources and capacity to accommodate for our increasing number of rescues as we are an independent organisation. Nevertheless, we will continue to persevere in our efforts to help more animals in need and to carry out our work with compassion and integrity.
    .bottom-banner
      .banner-cont
      .center-cont
        h3 OUR WORK
        p Learn more about our work and what your contribution will be funding for.
        a(href="/ourwork")
          button Our Work
</template>
<script>
export default {
  name: "AboutUs",
  metaInfo: {
    title: "About Us",
    meta: [
      {
        name: "description",
        content:
          "My Pets Haven is owned by Aunty Aileen and we have been operating since 2011. Our primary mission is to help more animals in need and find them a place to call their home.",
      },
      {
        name: "keywords",
        content:
          "mypetshaven, dogs, adopt, cats, adoption, shelter, volunteer, puppies, kittens, neuter, rescue, animals, nurture, injure, donate, pets, abandon, haven, aileen, setia, alam, strays, companion, declaw",
      },
    ],
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/variables.sass'

#aboutus
  .banner-section
    width: 100%
    img
      width: 100%
  .bottom-container
    text-align: left
    max-width: 1100px
    padding: 0 20px
    margin: 30px auto 30px auto
    h1
      margin-bottom: 20px
      color: $primary-orange
      font-family: 'RobotoSlab'
    .information-wrapper
      margin: 0 0 30px 0
      &.last
        margin-bottom: 100px
      h2
        color: $primary-orange
      .details
        text-align: left
        margin: 10px 0
        .infographic
          width: 100%
          img
            width: 100%
        .image-cont
          float: left
          width: 400px
          margin-right: 20px
          +mobile
            width: 100%
            margin-right: 0
          img
            max-width: 100%
        +mobile
          flex-direction: column
        .text-container
          p
            color: $primary-grey
  .bottom-banner
    width: 100%
    height: 400px
    position: relative
    // background-image: url('../assets/images/our-work/header.png')
    // background-size: cover
    // background-position: center center
    // filter: brightness(50%)
    // +mobile
    //   background-image: url('https://via.placeholder.com/600x400/333333')
    .banner-cont
      background-image: url('../assets/images/our-work/header.png')
      background-size: cover
      background-position: center center
      filter: brightness(50%)
      width: 100%
      height: 100%
      z-index: 0
    .center-cont
      position: absolute
      display: flex
      justify-content: center
      align-items: center
      flex-direction: column
      height: 100%
      max-width: 60%
      margin: 0 auto
      z-index: 5
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      +mobile
        max-width: 90%
      h3
        color: #fff
        font-size: 36px
        line-height: 40px
        margin-bottom: 5px
      p
        color: #fff
        font-size: 18px
        line-height: 22px
        margin-bottom: 30px
      button
        background-color: $primary-orange
        padding: 10px 20px
        font-weight: bold
        font-family: 'RobotoSlab'
        color: #fff
</style>
