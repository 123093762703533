<template>
    <v-container fill-height>
        <v-row align="center">
            <v-col cols= 6  >
                <v-btn  to="/admin/addAnimal" >
                    <span style="white-space: normal;">
                    Add Animal
                    </span>
                </v-btn >
                <v-btn  to="/admin/animalList" >
                    <span style="white-space: normal;">
                    View Animals
                    </span>
                </v-btn >
            </v-col>
            <v-col cols= 6>
                <v-btn  to="/admin/updateCreate" >
                    <span style="white-space: normal;">
                        Create Update
                        </span>
                </v-btn >
                <v-btn  to="/admin/updateList" >
                    <span style="white-space: normal;">
                        View Updates
                    </span>
                </v-btn >
            </v-col>
        </v-row>
        <v-row>
            <v-col cols= 6>
                <v-btn  to="/admin/blogCreate" >
                    <span style="white-space: normal;">
                        Create blog posts
                    </span>
                </v-btn >
                <v-btn  to="/admin/blogList" >
                    <span style="white-space: normal;">
                        View blog posts
                    </span>
                </v-btn >
            </v-col>
            <v-col cols= 6>
                <v-btn  to="/admin/volunteerList">
                    <span style="white-space: normal;">
                        View Volunteer Dates
                    </span>
                </v-btn >
            </v-col>
        </v-row>
        <v-row>
            <v-col cols= 6>
                <v-btn  to="/admin/enquiryList" >
                    <span style="white-space: normal;">
                        View Adoption enquiries
                    </span>
                </v-btn >

            </v-col>
            <v-col cols= 6>
                <v-btn  to="/admin/homeCMS" >
                    <span style="white-space: normal;">
                        Edit HomePage
                    </span>
                </v-btn >

            </v-col>
        </v-row>
    </v-container>

</template>

<script>
    export default {

    }
</script>

<style lang="scss" scoped>

    v-btn{
        white-space: normal;
    }

    span{
        font-size: 10px;
    }

</style>