<template lang="pug">
.content
  .banner-section
    .sliding-banner
      #bannerstate.slider(data-state="0")
        img(v-for="item of images", :src="item.URL", loading="lazy")
    .left-button(@click="moveLeft")
      img(src="../assets/images/general/arrow-o.svg")
    .right-button(@click="moveRight")
      img(src="../assets/images/general/arrow-o.svg")
  .dot-container
    .dot(
      v-for="(item, i) in images",
      :class="{ selected: slidePosition === i }",
      @click="changeSlider(i)"
    )
  .middle-section
    .mid-header
      h2 My Pets Haven
      p As an independent rescue and adoption shelter, we are always on the lookout for a helping hand. Here are a few ways in which you can learn more about us and how you can help us.
    .mid-content
      a(href="/aboutus")
        .mid-item
          img(src="../assets/images/index/about.png", loading="lazy")
          h3 About Us
          p We have been around for more than a decade. Read about our founder, our mission for stray animals and our vision for the future of our shelter.
      a(href="/adopt")
        .mid-item
          img(src="../assets/images/index/adopt.png", loading="lazy")
          h3 Adopt
          p Give our rescued animals a chance to be properly loved and cared for and a place to call their home.
      a(href="/volunteer")
        .mid-item
          img(src="../assets/images/index/vol.png", loading="lazy")
          h3 Volunteer
          p Join our volunteer day-programme to physically help us out at our shelter. Click to book a date here!
      a(href="/ourwork")
        .mid-item
          img(src="../assets/images/index/work.png", loading="lazy")
          h3 Our Work
          p Read on our past work and updates of our new rescues.
      a(href="/donate")
        .mid-item
          img(src="../assets/images/index/donate.png", loading="lazy")
          h3 Donate
          p Make a contribution to help rescue more needy animals off the streets, fund their medical bills and assist in providing necessities for our animals.
      a(href="/contact-us")
        .mid-item
          img(src="../assets/images/index/contact.png", loading="lazy")
          h3 Contact Us
          p Get our contact and location details here.
  .furry-section
    .furry-inner-section
      .furry-header
        h2 FURRY UPDATES
      .furry-content
        .furry-item(
          v-for="update of furryUpdates",
          @click="openArticle(update.id)"
        )
          img(:src="update.thumbnail.url")
          .bottom-container
            h3 {{ update.title }}
            p {{ update.date }}
            .read-more READ MORE

  .announcement-section
    .announcement-header
      h2 ANNOUNCEMENTS
    .announcement-content
      .announcement-left
        .top-text-container
          h3 {{ announcements[0].title }}
          p(v-html="announcements[0].content")
        .bottom-text-container
          p {{ announcements[0].date }}
          button.read-button(@click="openAnnouncement(announcements[0].id)")
            p Read more
            img(src="../assets/images/general/arrow-line-lo.svg")
      .announcement-right
        .rect-box(
          v-for="(announcement, index) in announcements",
          v-if="index > 0"
        )
          .title-date-container
            h3 {{ announcement.title }}
            p {{ announcement.date }}
          button.read-button(@click="openAnnouncement(announcement.id)")
            p Read more
            img(src="../assets/images/general/arrow-line-lo.svg")
        .short-rect-box(@click="openAnnouncement(announcements[0].id)")
          p See all announcements
          img(src="../assets/images/general/arrow-line-lo.svg")
</template>

<script>
import { db } from "../main";
export default {
  name: "Home",
  metaInfo: {
    title: "Home",
    meta: [
      {
        name: "description",
        content:
          "My Pets Haven is an independent animal shelter and adoption centre. We rescue stray animals, nurture and care for them until they're ready for adoption.",
      },
      {
        name: "keywords",
        content:
          "mypetshaven, dogs, adopt, cats, adoption, shelter, volunteer, puppies, kittens, neuter, rescue, animals, nurture, injure, donate, pets, abandon, haven, aileen, setia, alam, strays, companion, declaw",
      },
    ],
  },
  data() {
    return {
      images: [],
      announcements: [],
      furryUpdates: [],
      slidePosition: 0,
      totalImg: 0,
      start: 0,
      timeStart: 0,
      timeElapsed: 0,
    };
  },
  async mounted() {
    let blogRef = await db
      .collection("blogs")
      .orderBy("realDate", "desc")
      .limit(3);
    var test = [];
    await blogRef.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        var put = doc.data();
        put.id = doc.id;
        // doc.data() is never undefined for query doc snapshots
        test.push(put);
      });
    });
    this.furryUpdates = test;

    let announcementRef = await db
      .collection("updates")
      .orderBy("realDate", "desc")
      .limit(5);
    var test1 = [];
    await announcementRef.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        var put = doc.data();
        put.id = doc.id;
        // doc.data() is never undefined for query doc snapshots
        test1.push(put);
      });
    });

    this.announcements = test1;
    // console.log(this.announcements);

    let dbRef = await db.collection("homeBanners").doc("banners");
    await dbRef
      .get()
      .then((doc) => {
        this.images = doc.data().URLS;
        this.initSlider();
        window.requestAnimationFrame(this.moveSlider);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  destroyed() {
    window.cancelAnimationFrame(this.moveSlider);
  },

  methods: {
    initSlider() {
      this.slidePosition = 0;
      document
        .querySelector("#bannerstate")
        .setAttribute("data-state", this.slidePosition);
      this.totalImg = this.images.length - 1;
    },

    changeSlider(index) {
      this.slidePosition = index;
      document
        .querySelector("#bannerstate")
        .setAttribute("data-state", this.slidePosition);
      this.start = null;
      this.timeStart = 0;
      this.timeElapsed = 0;
    },

    moveSlider(timestamp) {
      if (this.start === null) {
        this.start = true;
        this.timeStart = timestamp;
      }

      this.timeElapsed = timestamp - this.timeStart;

      // console.log(this.timeElapsed);

      if (this.timeElapsed > 4500) {
        this.moveRight();
        this.start = null;
        window.cancelAnimationFrame(this.moveSlider);
      }
      window.requestAnimationFrame(this.moveSlider);
    },

    moveRight() {
      this.start = null;
      const maxRight = this.totalImg;
      // console.log(this.slidePosition);
      if (this.slidePosition < maxRight) {
        this.slidePosition++;
        document
          .querySelector("#bannerstate")
          .setAttribute("data-state", this.slidePosition);
      } else {
        this.slidePosition = 0;
        document
          .querySelector("#bannerstate")
          .setAttribute("data-state", this.slidePosition);
      }
      window.cancelAnimationFrame(this.moveSlider);
    },

    moveLeft() {
      this.start = null;
      const maxLeft = 0;
      const maxRight = this.totalImg;
      if (this.slidePosition > maxLeft) {
        this.slidePosition--;
        document
          .querySelector("#bannerstate")
          .setAttribute("data-state", this.slidePosition);
      } else {
        this.slidePosition = maxRight;
        document
          .querySelector("#bannerstate")
          .setAttribute("data-state", this.slidePosition);
      }
    },

    openAnnouncement(title) {
      const announcementID = "announcements/" + title;
      const link = window.location.href + announcementID;
      window.location.assign(link);
    },

    openArticle(title) {
      const announcementID = "ourwork/" + title;
      const link = window.location.href + announcementID;
      window.location.assign(link);
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/variables.sass'

.content
  width: 100%
  .banner-section
    width: 100%
    position: relative
    .sliding-banner
      position: relative
      overflow: hidden
      display: flex
      .slider
        display: flex
        transition: 1s ease-in
        align-items: flex-start
        img
          flex: 0 0 auto
          position: relative
          width: 100%
          max-height: 700px
          +mobile
            max-height: auto
    .left-button
      position: absolute
      top: 50%
      left: 30px
      transform: translateY(-50%)
      cursor: pointer
      img
        transform: rotate(180deg)
        width: 22px
    .right-button
      position: absolute
      top: 50%
      right: 30px
      transform: translateY(-50%)
      cursor: pointer
      img
        width: 22px

  .dot-container
    display: flex
    justify-content: center
    margin-top: 20px
    .dot
      background-color: #c6c6c6
      width: 15px
      height: 15px
      border-radius: 50%
      margin: 0 10px
      cursor: pointer
      &.selected
        background-color: $primary-orange
        box-shadow: 0 0 10px $primary-orange

  .middle-section
    max-width: 1100px
    padding: 0 20px
    margin: 50px auto
    display: flex
    justify-content: center
    flex-direction: column
    .mid-header
      margin-bottom: 30px
      h2
        color: $primary-orange
        font-size: 34px
        line-height: 38px
        margin-bottom: 20px
        +mobile
          font-size: 22px
          line-height: 26px
      p
        margin-top: 10px
        margin-bottom: 0
        color: $primary-grey
        font-size: 22px
        line-height: 26px
        +mobile
          font-size: 14px
          line-height: 18px
    .mid-content
      display: flex
      width: 100%
      flex-wrap: wrap
      +mobile
        justify-content: center
        flex-direction: column
      a
        width: 30%
        margin: 10px auto
        text-decoration: none
        +mobile
          width: 300px
        .mid-item
          padding: 20px 30px
          min-height: 450px
          &:last-child
            +mobile
              margin: 0 auto 0
          +mobile
            padding: 0
            margin: 0 auto 20px
            min-height: auto
          &:hover
            box-shadow: 0px 0px 30px 5px rgba(255, 171, 171, 0.36)
            transform: scale(1.05)
            transition: transform .3s ease-in
          img
            border-radius: 50%
            margin-bottom: 20px
          h3
            color: $primary-orange
            margin-bottom: 10px
            font-size: 20px
            line-height: 24px
          p
            color: $primary-grey
            font-weight: 600px
            font-size: 16px
            line-height: 20px
            margin: 0 auto

  .furry-section
    background-color: $primary-lighto
    padding: 50px 0
    .furry-inner-section
      max-width: 1100px
      padding: 0 20px
      margin: 0 auto
      .furry-header
        h2
          color: $primary-orange
          font-size: 34px
          line-height: 38px
          margin-bottom: 20px
          +mobile
            font-size: 22px
            line-height: 26px
      .furry-content
        display: flex
        justify-content: center
        margin-top: 30px
        width: 100%
        +mobile
          flex-wrap: wrap
        .furry-item
          margin: 0 20px
          background-color: #fff
          width: 33%
          max-width: 350px
          filter: drop-shadow(3px 5px 5px rgba(0, 0, 0, 0.25))
          backface-visibility: hidden
          cursor: pointer
          &:hover
            filter: drop-shadow(6px 8px 10px rgba(0, 0, 0, 0.25))
            .read-more
              color: #ffffff !important
              background-color: $primary-orange
          +mobile
            width: 300px
            margin: 0 auto 20px
          img
            width: 100%
            max-height: 300px
          .bottom-container
            padding: 10px 10px 20px 10px
            text-align: center
            h3
              font-size: 18px
              line-height: 22px
              color: $primary-orange
              margin-bottom: 5px
            p
              font-size: 14px
              line-height: 18px
              color: $primary-orange
            .read-more
              color: $primary-orange
              font-weight: bold
              border: 1px solid $primary-orange
              padding: 5px 20px
              width: 150px
              margin: 0 auto

  .announcement-section
    max-width: 1100px
    padding: 0 20px
    margin: 50px auto
    display: flex
    justify-content: center
    flex-direction: column
    .announcement-header
      h2
        color: $primary-orange
        font-size: 32px
        line-height: 36px
        margin-bottom: 20px
        +mobile
          font-size: 24px
          line-height: 28px
    .announcement-content
      margin-top: 30px
      display: flex
      justify-content: center
      +mobile
        flex-wrap: wrap
      .announcement-left
        width: 35%
        text-align: left
        border: 3px solid $primary-orange
        padding: 40px 35px
        position: relative
        background-color: $primary-orange
        cursor: pointer
        filter: drop-shadow(3px 5px 5px rgba(0, 0, 0, 0.25))
        &:hover
          filter: drop-shadow(6px 8px 10px rgba(0, 0, 0, 0.25))
          img
            animation: .5s moveRight forwards
        +mobile
          width: 100%
          padding: 20px
        .top-text-container
          h3
            word-wrap: break-word
            color: $primary-lighto
            font-weight: 900
            margin-bottom: 20px
            font-size: 24px
            line-height: 28px
            +mobile
              font-size: 18px
              line-height: 22px
          p
            word-wrap: break-word
            color: $primary-lighto
            font-weight: 400
            font-size: 18px
            line-height: 24px
            margin-bottom: 0
            +mobile
              font-size: 14px
              line-height: 18px

        .bottom-text-container
          display: flex
          justify-content: space-between
          margin-top: 40px
          // position: absolute
          // bottom: 40px
          width: 100%
          +mobile
            bottom: 20px
          p
            color: $primary-lighto
            font-weight: 400
            margin-bottom: 0
          .read-button
            display: flex
            align-items: center
            p
              color: $primary-lighto
              +mobile
                line-height: 18px
            img
              margin-left: 5px

      .announcement-right
        width: 40%
        text-align: left
        display: flex
        flex-direction: column
        margin-left: 30px
        +mobile
          width: 100%
          margin: 20px 0 0 0
        .rect-box
          background-color: $primary-orange
          display: flex
          justify-content: space-between
          padding: 10px
          margin: 0 0 20px 0
          cursor: pointer
          filter: drop-shadow(3px 5px 5px rgba(0, 0, 0, 0.25))
          backface-visibility: hidden
          -webkit-font-smoothing: antialiased
          -webkit-perspective: 1000
          &:hover
            filter: drop-shadow(6px 8px 10px rgba(0, 0, 0, 0.25))
            img
              animation: .5s moveRight forwards
          +mobile
            padding: 10px 20px
          .title-date-container
            h3
              color: $primary-lighto
              font-weight: 900
              margin: 0 0 5px 0
              font-size: 16px
              line-height: 20px
            p
              color: $primary-lighto
              font-weight: 400
              margin-bottom: 0
              font-size: 12px
              line-height: 16px
          .read-button
            display: flex
            align-items: center
            p
              color: $primary-lighto
              margin-bottom: 0
              +mobile
                line-height: 16px
            img
              margin-left: 5px

        .short-rect-box
          border: 3px solid $primary-orange
          display: flex
          align-items: center
          justify-content: center
          padding: 10px
          width: 230px
          background-color: $primary-orange
          cursor: pointer
          filter: drop-shadow(3px 5px 5px rgba(0, 0, 0, 0.25))
          &:hover
            filter: drop-shadow(6px 8px 10px rgba(0, 0, 0, 0.25))
            img
              animation: .5s moveRight forwards
          p
            color: $primary-lighto
            text-decoration: underline
            margin: 0
          img
            margin-left: 5px

@keyframes moveRight
  0%
    transform: translateX(0)
  100%
    transform: translateX(3px)

#bannerstate
  transition: transform 800ms
  transition-timing-function: ease-in

#bannerstate[data-state='0']
  transform: translateX(0%)

#bannerstate[data-state='1']
  transform: translateX(-100%)

#bannerstate[data-state='2']
  transform: translateX(-200%)

#bannerstate[data-state='3']
  transform: translateX(-300%)

#bannerstate[data-state='4']
  transform: translateX(-400%)

#bannerstate[data-state='5']
  transform: translateX(-500%)
</style>
