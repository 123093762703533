<template lang="pug">
#announcements
  .announcements-wrapper
    .header-title
      h1 ANNOUNCEMENTS
    .content-wrapper
      .content-left
        .news-box
          .news-title
            h2 {{ mainAnnouncement.title }}
            p {{ mainAnnouncement.date }}
          .news-info
            .news-content
              p(v-html="mainAnnouncement.content")
        hr
        #news
          .news-box(v-for="announcement in announcements")
            .news-title
              h2 {{ announcement.title }}
              p {{ announcement.date }}
            .news-info
              .news-content
                p(v-html="announcement.content")
        .last-container(v-if="finalPage")
          hr
          h2 You have reached the end of our announcements. <br class="desktop">Do stay tuned for more future updates!
</template>

<script>
import { db } from "../main";
// import infiniteScroll from "vue-infinite-scroll";
export default {
  name: "AnnouncementPage",
  data() {
    return {
      lastDocument: "",
      mainAnnouncement: null,
      announcements: [],
      articleID: "",
      articleTitle: "heidi is free from distemper!!!",
      loading: false,
      apples: [],
      finalPage: false,
      nextItem: 2,
    };
  },
  methods: {
    goToArticle(title) {
      this.articleID = "/" + title;
      const location = window.location.href + this.articleID;
      window.location.assign(location);
    },

    async loadArticle() {
      let dbRef = await db.collection("updates").doc(this.$route.params.id);
      await dbRef
        .get()
        .then((doc) => {
          this.mainAnnouncement = doc.data();
        })
        .catch((error) => {
          console.error(error);
        });
      let announcementRef = await db
        .collection("updates")
        .where("realDate", "!=", this.mainAnnouncement.realDate)
        .orderBy("realDate", "desc")
        .limit(2);

      var test1 = [];
      await announcementRef.get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          var put = doc.data();
          put.id = doc.id;
          test1.push(put);
        });
      });

      var lastDoc;
      await announcementRef.get().then(function (documentSnapshots) {
        lastDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];
      });
      this.lastDocument = lastDoc;
      this.announcements = test1;
    },

    async loadMore() {
      if (!this.finalPage && !this.loading) {
        let announcementRef = await db
          .collection("updates")
          .where("realDate", "!=", this.mainAnnouncement.realDate)
          .orderBy("realDate", "desc")
          .startAfter(this.lastDocument)
          .limit(3);

        var test1 = this.announcements;
        await announcementRef.get().then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            var put = doc.data();
            put.id = doc.id;
            if (!test1.includes(put)) test1.push(put);
            this.loading = true;
          });
        });

        var lastDoc;
        await announcementRef.get().then(function (documentSnapshots) {
          lastDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];
        });

        if (lastDoc === undefined) {
          this.finalPage = true;
        } else {
          this.lastDocument = lastDoc;
          this.announcements = test1;
        }
      }
    },
  },
  async created() {
    await this.loadArticle();
    window.addEventListener("scroll", () => {
      if (
        window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight - 2
      ) {
        this.loadMore();
      }
    });
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/variables.sass'

#announcements
  height: 100%
  .announcements-wrapper
    max-width: 1100px
    padding: 30px 20px 0 20px
    margin: 0 auto
    .header-title
      h1
        color: $primary-orange
        font-weight: 900
        font-size: 36px
        line-height: 40px
        margin-bottom: 30px
        +mobile
          font-size: 24px
          line-height: 28px
    .content-wrapper
      width: 100%
      display: flex
      flex-direction: row
      margin: 0 auto
      justify-content: center
      .content-left
        width: 70%
        text-align: left
        +mobile
          width: 100%
        .news-box
          background-color: #fff
          border: 3px solid $primary-orange
          padding: 20px
          display: flex
          flex-direction: column
          margin-bottom: 30px
          .news-title
            margin-bottom: 0
            h2
              font-size: 28px
              line-height: 32px
              color: $primary-orange
              font-family: 'RobotoSlab'
              font-weight: bold
              margin-bottom: 5px
              +mobile
                font-size: 24px
                line-height: 28px
            p
              font-size: 16px
              line-height: 22px
              color: $primary-grey
              font-weight: bold
              margin: 0 0 10px 0
              +mobile
                font-size: 14px
                line-height: 18px
          .news-info
            .news-content
              ::v-deep span
                color: $primary-grey !important
                font-size: 16px
                line-height: 22px
                +mobile
                  font-size: 14px
                  line-height: 18px

              ::v-deep p
                color: $primary-grey !important
                font-size: 16px
                line-height: 22px
                +mobile
                  font-size: 14px
                  line-height: 18px

              ::v-deep strong
                color: $primary-grey !important
                font-size: 16px
                line-height: 22px
                +mobile
                  font-size: 14px
                  line-height: 18px

hr
  color: $primary-grey
  width: 70%
  margin: 30px auto
  opacity: .5

.empty-box
  text-align: center
  margin-bottom: 50px
  h3
    font-size: 16px
    line-height: 20px
    color: $primary-orange
    font-family: 'RobotoSlab'
    font-weight: bold

.last-container
  text-align: center
  margin-bottom: 50px
  h2
    font-size: 16px
    line-height: 20px
    color: $primary-orange
    font-family: 'RobotoSlab'
    font-weight: bold

.news-content
  width: 100%
  word-wrap: break-word
</style>
