<template>
  <v-container class="mainContainer" fluid ma-0 pa-0>
    <v-overlay :value="overlay">
      <v-card color="white" class="cardder text-center popup rounded-lg">
        <v-card-text>
          <h1 class="overlaytext name">Volunteering <br />Confirmed</h1>
          <!-- <h1 class="overlaytext name top">Confirmed!</h1> -->
          <h1 class="overlaytext bot">
            Thank you for volunteering and we hope to see you soon!
          </h1>
          <v-btn
            raised
            rounded
            class="white--text"
            color="rgb(255,151,133)"
            to="/"
            >Back to Home</v-btn
          >
        </v-card-text>
      </v-card>
    </v-overlay>
    <div class="banner-section">
      <img
        src="../assets/images/volunteer/banner.png"
        loading="lazy"
        class="desktop"
      />
      <img
        src="../assets/images/volunteer/banner.png"
        loading="lazy"
        class="mobile"
      />
    </div>
    <div class="contentWrapper">
      <v-container pa-0>
        <v-row align="start" justify="start" class="desk" no-gutters>
          <h2>Why Volunteer?</h2>
        </v-row>
        <v-row align="start" justify="start" class="desk" no-gutters>
          <p>
            Volunteer a day with us if you’re interested in contributing your
            time and energy to physically help us out at our shelter! We
            severely lack manpower especially in the morning when we are packed
            with work to start off the day. We welcome anybody and everybody who
            is willing to learn and who would love to work with animals. No
            experience necessary as we will guide you through the tasks
            throughout the day.
          </p>
          <div class="rough">
            <p>Rough list of a day’s work :</p>
            <ul>
              <li><p>Walking the dogs.</p></li>
              <li><p>Cleaning the shelter.</p></li>
              <li><p>Assist in rescue work if any.</p></li>
              <li><p>Bathing the dogs if needed.</p></li>
              <li><p>Arranging newspaper.</p></li>
              <li><p>Play with our animals!</p></li>
            </ul>
          </div>
          <p>
            For our Muslim friends, you can opt not to work with the dogs if
            you’re not comfortable. No worries as you can be in charge of our
            cats and cleaning the centre!
          </p>
          <p>
            We are always in need of photographers to take pictures of our animals for adoption too! Read more about our volunteer day-programme
            <a href= https://mypetshaven.org/ourwork/Yf23PZaU3iAGLefuw3IR> here </a>


          </p>
        </v-row>
        <v-row align="start" justify="start" class="desk" no-gutters>
          <h2>How to Volunteer?</h2>
        </v-row>
        <v-row align="start" justify="start" class="desk" no-gutters>
          <p>
            Simply fill up the form below and select a date and time slot that
            suits you. Feel free to book both available time slots if you plan
            to stay the whole day at our centre. Once you submit the form, the
            slot will automatically be locked for you. Each time slot will only
            be available for up to 5 volunteers. For larger groups that wish to
            volunteer with us or to cancel a booking, please drop us an email at
            <a href="mailto:lovemypetshaven@gmail.com">
              lovemypetshaven@gmail.com</a
            >.
          </p>
          <div class="rough">
            <p>Instructions:</p>
            <ul>
              <li>
                <p>
                  Take a look at our <a class='link' href="https://mypetshaven.org/ourwork/Yf23PZaU3iAGLefuw3IR" target="_blank">schedule</a> here before booking!
                </p>
              </li>
              <li>
                <p>
                  Bring old newspapers for us if you have any. We need as many
                  as we can get!
                </p>
              </li>
              <li>
                <p>Do be punctual for the time slot that you have booked.</p>
              </li>
              <li>
                <p>
                  Bring extra clothes for yourself just in case you get sweaty.
                </p>
              </li>
              <li>
                <p>
                  Have some light breakfast before coming for our morning slot.
                </p>
              </li>
              <li>
                <p>
                  Call us at 0196632828 (Aileen) if you get lost on the way to
                  our centre or for any matters on the day of volunteering.
                </p>
              </li>
            </ul>
          </div>
        </v-row>
      </v-container>
      <v-row class="justify-center">
        <v-col class="justify-center formCont">
          <h2>Volunteer Form</h2>
          <v-form
            @submit.prevent="submitVolunteer"
            ref="form"
            v-model="isFormValid"
          >
            <v-row>
              <v-col sm="6" cols="12">
                <label class="text-start labels">Name</label>
                <v-text-field outlined v-model="name"></v-text-field>
              </v-col>
              <v-col sm="6" cols="12">
                <label class="text-start labels">Date of Birth</label>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-model="dob"
                      persistent-hint
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="#FF9785"
                    year-icon="mdi-calendar-blank"
                    prev-icon="mdi-skip-previous"
                    next-icon="mdi-skip-next"
                    v-model="dob"
                    width="500px"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col sm="6" cols="12">
                <label class="text-start labels">Mobile Number</label>
                <v-text-field
                  outlined
                  v-model="phoneNumber"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col sm="6" cols="12">
                <label class="text-start labels">Email</label>
                <v-text-field
                  outlined
                  v-model="email"
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
              <v-col xs="12">
                <v-date-picker
                  @change="getDate"
                  v-model="date"
                  full-width
                  height="500px"
                  :min="currentDate"
                  scrollable
                  calendar-class="bigDate"
                  color="#FF9785"
                  :allowed-dates="allowedDates"
                >
                </v-date-picker>
              </v-col>
              <v-col cols="12">
                <label class="text-start labels">Slots</label>
                <v-select
                  v-model="shift"
                  @change="calculateVolunteersRemaining"
                  outlined
                  :items="availableShifts"
                  :disabled="shiftDisabled"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <label class="text-start labels">Amount of People</label>
                <v-slider
                  step="1"
                  @change="allowEmail"
                  v-model="numberOfVolunteers"
                  outlined
                  min="0"
                  :max="volunteersLeft"
                >
                  <template v-slot:append>
                    <v-text-field
                      v-model="numberOfVolunteers"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      type="number"
                      style="width: 50%"
                      min="0"
                      :max="volunteersLeft"
                    ></v-text-field>
                  </template>
                </v-slider>
              </v-col>
              <!-- <v-col cols="12">
                <label class="text-left labelTitle">Terms and Conditions</label>
                <p class="tnc">
                  1. Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Recusandae modi provident libero vero omnis tempora nobis
                  aliquam obcaecati fugit assumenda? Obcaecati dolorum labore
                  sequi amet quam cumque repellat veniam in.
                </p>
                <p class="tnc">
                  2. Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Recusandae modi provident libero vero omnis tempora nobis
                  aliquam obcaecati fugit assumenda? Obcaecati dolorum labore
                  sequi amet quam cumque repellat veniam in.
                </p>
              </v-col> -->
            </v-row>
            <v-btn
              class="white--text"
              color="rgb(255,151,133)"
              type="submit"
              :disabled="checkButton"
              >Volunteer Now!</v-btn
            >
          </v-form>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>


<script>
import { db } from "../main";
import axios from "axios";
export default {
  data: () => ({
    phoneNumber: "",
    dob: "",
    occupiedDates: [],
    currentDate: new Date().toISOString().substr(0, 10),
    isFormValid: false,
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    menu2: false,
    overlay: false,
    shift: "",
    name: "",
    email: "",
    emailDisabled: true,
    shiftDisabled: true,
    volunteers: [],
    shifts: [],
    volunteersLeft: 0,
    numberOfVolunteers: 0,
    availableShifts: [],
    maximumVolunteers: 0,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    isFull: false,
  }),
  metaInfo: {
    title: "Volunteer",
    meta: [
      {
        name: "description",
        content:
          "Join our day-volunteer programme to meet our animals and help us out in our centre. There are opportunities for you to volunteer as a photographer as well.",
      },
      {
        name: "keywords",
        content:
          "mypetshaven, dogs, adopt, cats, adoption, shelter, volunteer, puppies, kittens, neuter, rescue, animals, nurture, injure, donate, pets, abandon, haven, aileen, setia, alam, strays, companion, declaw, volunteering, photography, my, pets, haven,",
      },
    ],
  },
  computed: {
    checkButton() {
      return !(
        this.shift.length > 0 &&
        this.name.length > 0 &&
        this.numberOfVolunteers > 0 &&
        this.isFormValid
      );
    },
    computedDateFormatted() {
      return this.formatDate(this.dob);
    },
  },
  created() {
    db.collection("volunteerSchedule").onSnapshot((snapshotChange) => {
      snapshotChange.forEach((doc) => {
        if (doc.data().isFull) {
          this.occupiedDates.push(doc.id);
        }
      });
      // console.log(this.occupiedDates);
    });
  },
  methods: {
    calculateVolunteersRemaining() {
      var shift = this.shift.slice(0, -20);
      // console.log(shift);
      for (var i of this.shifts) {
        if (i.time === shift) {
          this.volunteersLeft = i.maximumVolunteers - i.currentVolunteers;
        }
      }
    },

    allowEmail() {
      this.emailDisabled = false;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },

    async getDate() {
      var volunteerRef = db.collection("volunteerSchedule").doc(this.date);
      var data;

      await volunteerRef
        .get()
        .then(function (doc) {
          if (doc.exists) {
            data = doc.data();
          } else {
            // doc.data() will be undefined in this case
            volunteerRef.set({
              sessions: [
                {
                  time: "6:00 am - 1:00 pm",
                  maximumVolunteers: 5,
                  currentVolunteers: 0,
                  volunteers: [],
                },
                {
                  time: "4:00 pm - 6:00 pm",
                  maximumVolunteers: 2,
                  currentVolunteers: 0,
                  volunteers: [],
                },
              ],
              isFull: false,
            });
          }
        })
        .catch(function (error) {
          console.error("Error getting document:", error);
        });

      if (data == null) {
        this.shifts = [
          {
            time: "6:00 am - 1:00 pm",
            maximumVolunteers: 5,
            currentVolunteers: 0,
            volunteers: [],
          },
          {
            time: "4:00 pm - 6:00 pm",
            maximumVolunteers: 2,
            currentVolunteers: 0,
            volunteers: [],
          },
        ].sort();
        this.availableShifts = [
          "6:00 am - 1:00 pm (5 remaining slots)",
          "4:00 pm - 6:00 pm (2 remaining slots)",
        ].sort();
      } else {
        this.availableShifts = [];
        this.volunteers = data.volunteers;
        this.shifts = data.sessions;
        this.maximumVolunteers = data.maximumVolunteers;
        for (var i of data.sessions) {
          if (i.currentVolunteers < i.maximumVolunteers) {
            var remaining = i.maximumVolunteers - i.currentVolunteers;
            this.availableShifts.push(
              i.time + " (" + remaining + " remaining slots)"
            );
          }
        }
      }
      this.availableShifts.sort();
      this.shiftDisabled = false;
    },
    allowedDates(val) {
      return this.occupiedDates.indexOf(val) === -1;
    },

    async submitVolunteer() {
      document.getElementById("loading-screen").style.display = "flex";
      for (let i = 0; i < this.shifts.length; i++) {
        if (this.shifts[i].time == this.shift.slice(0, -20)) {
          this.shifts[i].currentVolunteers += this.numberOfVolunteers;
          this.shifts[i].volunteers.push({
            name: this.name,
            contactEmail: this.email,
            phoneNumber: this.phoneNumber,
            dob: this.dob,
            numberOfPeople: this.numberOfVolunteers,
          });
          break;
        }
      }
      var numberOfFullShifts = 0;
      for (let i = 0; i < this.shifts.length; i++) {
        if (
          this.shifts[i].maximumVolunteers <= this.shifts[i].currentVolunteers
        ) {
          numberOfFullShifts += 1;
        }
      }

      if (numberOfFullShifts == this.shifts.length) {
        this.isFull = true;
      }
      var date = { isFull: this.isFull, sessions: this.shifts };
      db.collection("volunteerSchedule")
        .doc(this.date)
        .update(date)
        .then(() => {
            var body = {
              name: this.name,
              email: this.email,
              people: this.numberOfVolunteers,
              shiftChosen: this.shift.slice(0, -20),
              date: this.date,
              phoneNumber: this.phoneNumber,
            };
            this.name = "";
            axios
              .post("https://smtp-pog-request.onrender.com/volunteer", body, {
                headers: {},
              })
              .then(() => {
                document.getElementById("loading-screen").style.display = "none";
                this.overlay = true;
              });
          })
        .catch((error) => {
          console.error(error);
          document.getElementById("loading-screen").style.display = "none";
        });
    },
  },
};
</script>
<style lang="sass" scoped>
@import '../sass/variables.sass'

.banner-section
  width: 100%
  img
    width: 100%

.mainContainer
  background: white
  min-height: 100%

.contentWrapper
  max-width: 1100px
  padding: 0 20px
  margin: 30px auto 50px

.formCont
  max-width: 1185px
  margin: 20px auto

.btn-primary
  margin-right: 12px

.labels
  font-size: 20px
  font-weight: bold
  color: $primary-grey
  margin-bottom: 1%
  white-space: nowrap
  font-family: 'RobotoSlab' !important

.calendar
  color: teal
  height: 400px

.desk
  text-align: start
  p
    color: $primary-grey
    font-size: 16px
    line-height: 22px
    +mobile
      font-size: 14px
      line-height: 18px

h2
  font-size: 32px
  line-height: 36px
  color: $primary-orange
  margin-bottom: 10px
  +mobile
    font-size: 24px
    line-height: 28px

.images
  img
    width: 100%

.title
  color: $primary-orange
  font-size: 36px
  line-height: 40px

.text
  margin-bottom: 2%

.bigDate
  height: 500px

.top
  margin-top: 2%
  margin-bottom: 15%

.overlaytext
  font-size: 32px
  line-height: 36px
  font-family: 'RobotoSlab'

.bot
  line-height: 1
  color: $primary-grey !important
  margin-bottom: 10%
  font-size: 18px
  line-height: 22px

.name
  color: $primary-orange !important

.cardder
  display: flex
  align-items: center
  +mobile
    width: 300px
    height: 400px
  +desktop
    width: 350px
    height: 350px

.labelTitle
  font-size: 24px
  font-family: 'RobotoSlab'
  color: $primary-grey
  font-weight: bold
  margin-bottom: 10px
  text-decoration: underline

.tnc
  font-size: 18px
  line-height: 22px
  color: $primary-grey2
  text-align: left
</style>