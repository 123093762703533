<template>
    <v-container fluid >
        <v-row justify="start" align="start" >
            <v-col>
                <v-simple-table class="table table-striped text-center " width="100vw">
                    <thead >
                        <tr>
                            <th class="title text--primary text-center">Date</th>
                            <th class="title text--primary text-center">Maximum Capacity</th>
                            <th class="title text--primary text-center">Morning</th>
                            <th class="title text--primary text-center">Evening</th>
                            <th class="title text--primary text-center">More Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="date in dates" :key="date.date">
                            <td>{{ date.date }}</td>
                            <td>{{ date.isFull ? "Yes" : "No"}}</td>
                            <td > {{date.session[0].time.includes('am')? date.session[0].currentVolunteers : date.session[1].currentVolunteers}} </td>
                            <td > {{ date.session[0].time.includes('am')? date.session[1].currentVolunteers : date.session[0].currentVolunteers}} </td>
                            <td>
                                <v-btn :to = date.editURL class="btn-primary">View More</v-btn>
                                <v-btn color="error" type="submit" @click.prevent="deleteDate(date.date)" class="btn btn-danger">Delete</v-btn>
                            </td>
                        </tr>
                    </tbody>
            </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { db } from '../main';

    export default {
        data() {
            return {
                dates: []
            }
        },
        async created() {
            await db.collection('volunteerSchedule').onSnapshot((snapshotChange) => {
                this.dates = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().sessions[0].volunteers.length > 0 || doc.data().sessions[1].volunteers.length > 0){
                        this.dates.push({
                            date: doc.id,
                            isFull: doc.data().isFull,
                            session: doc.data().sessions,
                            editURL: "/admin/volunteerEdit/".concat(doc.id)
                        })
                        console.log(doc.data().sessions);
                    }
                });
            })
        },
        methods: {
            deleteDate(id){
              if (window.confirm("Do you really want to delete?")) {
                db.collection("volunteerSchedule").doc(id).delete().then(() => {
                    console.log("Date deleted!");
                })
                .catch((error) => {
                    console.error(error);
                })
              }
            }
        }
    }
</script>

<style>
    .btn-primary {
        margin-right: 12px;
    }
    .concat{
        white-space: nowrap;
        overflow: hidden;
        width: 50px;
        height: 30px;
        text-overflow: ellipsis;
        word-break: break-all;
        word-wrap:break-word;
    }
</style>