<template>
    <v-container fluid >
        <v-row justify="start" align="start" >
            <v-col>
                <v-simple-table class="table table-striped text-center " width="100vw">
                    <thead >
                        <tr>
                            <th class="title text--primary text-center">Title</th>
                            <th class="title text--primary text-center">Date</th>
                            <th class="title text--primary text-center">Content</th>
                            <th class="title text--primary text-center">Settings</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="update in Updates" :key="update.key">
                            <td>{{ update.title }}</td>
                            <td>{{ update.date }}</td>
                            <td v-if="update.content.length>0">Please view in Edit</td>
                            <td v-else>None</td>
                            <td>
                                <v-btn :to= update.editURL class="btn-primary">Edit</v-btn>
                                <v-btn color="error" type="submit" @click.prevent="deleteUpdate(update.key)" class="btn btn-danger">Delete</v-btn>
                            </td>
                        </tr>
                    </tbody>
            </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { db } from '../main';

    export default {
        data() {
            return {
                Updates: []
            }
        },
        created() {
            db.collection('updates').orderBy("realDate", "desc").onSnapshot((snapshotChange) => {
                this.Updates = [];
                snapshotChange.forEach((doc) => {
                    this.Updates.push({
                        key: doc.id,
                        title: doc.data().title,
                        content: doc.data().content,
                        date: doc.data().date,
                        editURL: "/admin/updateEdit/".concat(doc.id)
                    })
                });
            })
        },
        methods: {
            deleteUpdate(id){
              if (window.confirm("Do you really want to delete?")) {
                for(this.update of this.Updates){
                    if(id === this.update.key){
                        this.deletingImgs = this.update.imageList;
                        break;
                    }
                }
                db.collection("updates").doc(id).delete().then(() => {
                    console.log("update deleted!");
                })
                .catch((error) => {
                    console.error(error);
                })
              }
            }
        }
    }
</script>

<style>
    .btn-primary {
        margin-right: 12px;
    }
    .concat{
        white-space: nowrap;
        overflow: hidden;
        width: 50px;
        height: 30px;
        text-overflow: ellipsis;
        word-break: break-all;
        word-wrap:break-word;
    }
</style>