<template>
  <v-container>
    <v-row class="justify-center">
      <v-card>
        <v-card-title> Date: {{ this.date }} </v-card-title>
        <v-card-text>
          <h3>Number of sessions: {{ this.sessionAmount }}</h3>
        </v-card-text>

        <v-card
          v-for="session in this.dateContent.sessions"
          :key="session.time"
        >
          <v-card-title> Session for time : {{ session.time }} </v-card-title>
          <v-card-text>
            <h3>
              Maximum amount of Volunteers: {{ session.maximumVolunteers }}
            </h3>
            <v-card
              class="card"
              v-for="volunteer in session.volunteers"
              :key="volunteer.contactEmail"
            >
              <v-card-text>
                <p>Name of Volunteer: {{ volunteer.name }}</p>
                <p>Contact Email: {{ volunteer.contactEmail }}</p>
                <p>Phone Number: {{ volunteer.phoneNumber}}</p>
                <p>Number of People: {{volunteer.numberOfPeople}}</p>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="error"
                  x-small
                  block
                  type="submit"
                  @click.prevent="deleteVolunteer(session, volunteer)"
                  >Delete Volunteer</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
        <v-card-actions>
          <v-btn to="/admin/volunteerList">Go Back</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>


<script>
import { db } from "../main";
export default {
  data: () => ({
    dateContent: [],
    date: "",
    sessionAmount: 0,
  }),

  created() {
    let dbRef = db.collection("volunteerSchedule").doc(this.$route.params.id);
    dbRef
      .get()
      .then((doc) => {
        this.dateContent = doc.data();
        this.date = doc.id;
        this.sessionAmount = doc.data().sessions.length;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    deleteVolunteer(session, volunteer) {
      // console.log(session);
      // console.log(volunteer);

      if (window.confirm("Do you really want to delete?")) {
        for (var i = this.dateContent.sessions.length - 1; i >= 0; i--) {
          if (this.dateContent.sessions[i].time === session.time) {
            for (
              var j = this.dateContent.sessions[i].volunteers.length - 1;
              j >= 0;
              j--
            ) {
              if (
                this.dateContent.sessions[i].volunteers[j].name ===
                  volunteer.name &&
                this.dateContent.sessions[i].volunteers[j].contactEmail ===
                  volunteer.contactEmail
              ) {
                this.dateContent.sessions[i].currentVolunteers = this.dateContent.sessions[i].currentVolunteers - this.dateContent.sessions[i].volunteers[j].numberOfPeople
                this.dateContent.sessions[i].volunteers.splice(j, 1)
                break;
              }
            }
          }
        }
        var numberOfFullShifts = 0;
        for (let i = 0; i < this.dateContent.sessions.length; i++) {
          if (
            this.dateContent.sessions[i].maximumVolunteers <=
            this.dateContent.sessions[i].volunteers.length
          ) {
            numberOfFullShifts += 1;
          }
        }

        if (numberOfFullShifts < this.dateContent.sessions.length) {
          this.dateContent.isFull = false;
        }
        db.collection("volunteerSchedule")
          .doc(this.date)
          .update(this.dateContent)
          .then(() => {
            console.log("Date updated!");
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
};
</script>
<style>
.btn-primary {
  margin-right: 12px;
}
label {
  display: block;
}
.calendar {
  color: teal;
  height: 400px;
}
.card {
  margin-top: 12px;
  margin-bottom: 12px;
}
</style>